import React, { useState, useEffect } from "react";
import {
  getCurrentEvent,
  getNextEvent,
  advanceSimulation,
} from "BudgetSimulatorAPIService";
import { formatDate } from "utils";
import Payday from "../Payday";
import NextEvent from "../NextEvent";
import TransactionComponent from "../TransactionComponent";
import PhaseChangeComponent from "../PhaseChangeComponent";
import GameComplete from "../GameComplete";
import LoadingPhrases from "../LoadingPhrases";
import FailurePopup from "../FailurePopup";

const OptionsComponent = ({
  simulationId,
  studentId,
  onSubmit,
  onClose,
  accounts = [],
  isSubmitting = false,
}) => {
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const [advance, setAdvance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFailure, setShowFailure] = useState(false);


  useEffect(() => {
    const fetchEvent = async () => {
      // Reset states when fetching new event
      setError(null);
      setEvent(null);
      setAdvance(null);
      setSelectedChoice(null);
      setLoading(true);

      if (!simulationId || !studentId) {
        setError("Missing required simulation or student ID");
        setLoading(false);
        return;
      }

      try {
        // First try to get current event
        let currentEvent;
        try {
          currentEvent = await getCurrentEvent(simulationId, studentId);
        } catch (error) {
          // If we get a 404, treat it as no current event
          if (error.response?.status === 404) {
            currentEvent = null;
          } else {
            throw error;
          }
        }

        if (currentEvent?.eventId) {
          setEvent(currentEvent);
          setAdvance(null);
        } else {
          // If no current event, get next event
          try {
            const advance = await advanceSimulation(simulationId, studentId);
            if (!advance) {
              throw new Error("No advance data received");
            }
            setAdvance(advance);
            setEvent(null);
          } catch (error) {
            throw new Error("Failed to advance simulation: " + error.message);
          }
        }
      } catch (error) {
        console.error("Error in event flow:", error);
        setError(error.message || "An error occurred while fetching the event");
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [simulationId, studentId]);

  console.log(advance);

  useEffect(() => {
    // Check for game failure in advance response
    if (advance?.isGameFailed) {
      setShowFailure(true);
    }
  }, [advance]);

  const handleChoiceClick = (choice) => {
    if (!choice) return;

    if (selectedChoice?.choiceId === choice.choiceId) {
      setSelectedChoice(null);
      return;
    }
    setSelectedChoice(choice);
    setError(null);
  };

  const handleSubmit = () => {
    if (!selectedChoice || !advance?.eventId) {
      setError("Please make a selection before continuing");
      return;
    }
    onSubmit(selectedChoice.choiceId, advance.eventId);
  };

  // Show loading state
  if (loading) {
    return <LoadingPhrases />;
  }

  // Show error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center p-8 gap-4">
        <div className="text-red-600 text-center">{error}</div>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    );
  }

  // Handle game failure first
  if (advance?.isGameFailed) {
    return (
      <FailurePopup
        isOpen={showFailure}
        onClose={() => {
          setShowFailure(false);
          setAdvance(null); // Clear the advance state to fully remove the component
          onClose(); // Close the parent modal as well
        }}
        data={{
          failureDate: advance.failureDate,
          failureReason: advance.failureReason,
          isGameFailed: advance.isGameFailed,
          warnings: advance.warnings || [],
          result: advance.result || {},
        }}
        simulationId={simulationId}
        studentId={studentId}
      />
    );
  }

  // Handle current event
  if (event?.eventId) {
    return <NextEvent event={event} onSubmit={onSubmit} accounts={accounts} />;
  }

  // Handle advanced events
  if (!advance || !advance.result) {
    return (
      <div className="flex flex-col items-center justify-center p-8 gap-4">
        <div className="text-gray-600 text-center">Loading next event...</div>
      </div>
    );
  }

  // Safely handle eventTypes
  const eventTypes = (() => {
    if (!advance.result.eventTypes) return [];
    return Array.isArray(advance.result.eventTypes)
      ? advance.result.eventTypes
      : [advance.result.eventTypes];
  })();

  // If it's only a regular event, render NextEvent
  if (
    eventTypes.length === 1 &&
    eventTypes[0] === "Event" &&
    advance.result?.nextEvent
  ) {
    return (
      <NextEvent
        event={advance.result.nextEvent}
        onSubmit={onSubmit}
        accounts={accounts}
        isSubmitting={isSubmitting}
      />
    );
  }

  // If it's a phase change, render PhaseChangeComponent
  if (eventTypes.includes("PhaseChange")) {
    return (
      <PhaseChangeComponent phaseInfo={advance} onClose={onClose} />
    );
  }

  // If it's a game complete phase change, render GameCompleteComponent
  if (eventTypes.includes("PhaseChangeGameComplete")) {
    return <GameComplete gameInfo={advance.result} onClose={onClose} />;
  }

  // If it contains transaction events (Payday/Expense/CreditCardPayment), use TransactionComponent
  const hasTransactionEvents = eventTypes.some((type) =>
    ["Payday", "Expense", "CreditCardPayment"].includes(type)
  );
  if (hasTransactionEvents) {
    return (
      <TransactionComponent
        transactionInfo={advance.result}
        onClose={onClose}
      />
    );
  }

  if (eventTypes.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-8 gap-4">
        <div className="text-gray-600 text-center">
          Time has advanced, but there were no new events.
        </div>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    );
  }

  // Fallback for unknown event types
  return (
    <div className="flex flex-col items-center justify-center p-8 gap-4">
      <div className="text-gray-600 text-center">
        Unhandled event type: {eventTypes.join(", ")}
      </div>
      <button
        onClick={onClose}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Close
      </button>
    </div>
  );
};

export default OptionsComponent;
