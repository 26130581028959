import { apiClient } from "AuthService";

export const fetchInfo = async () => {
    const response = await apiClient('/api/UserInfo', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch user info (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const fetchStudentId = async () => {
    try {
        const response = await apiClient('/api/Students/', {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        })
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to fetch student ID: ${errorText} (Status: ${response.status})`);
        }

        const data = await response.json();
        return data[0]?.studentId; // Adjust as needed
    } catch (error) {
        console.error('Error fetching student ID:', error.message);
        console.error('Error details:', error);
        throw error; // Re-throw the error for further handling if needed
    }
};

export const fetchStudentTradeOrders = async (studentId) => {
    const response = await apiClient(`/api/Simulator/Student/${studentId}/TradeOrders`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch student trade orders (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const fetchStudentPortfolioGraph = async (studentId) => {
    const response = await apiClient(`/api/Simulator/Student/${studentId}/PortfolioGraph`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch student portfolio graph (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const fetchStudentStocks = async (studentId) => {
    const response = await apiClient(`/api/Simulator/Student/${studentId}/Stocks`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch student stocks (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const fetchStockInfo = async (symbol) => {
    const response = await apiClient(`/api/Simulator/Stock/${symbol}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch stock info (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const postTrade = async (studentId, tradeData) => {
    const response = await apiClient(`/api/Simulator/Student/${studentId}/Trade`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(tradeData),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Trade failed: ${errorText} (Status: ${response.status})`);
    }

    const responseData = await response.json();
    return responseData;
};

export const searchStocks = async (searchTerm) => {
    try {
        const response = await apiClient(`/api/Simulator/Search/${searchTerm}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch stocks (Status: ${response.status})`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching stocks:', error.message);
        console.error('Error details:', error);
        throw error; // Re-throw the error for further handling if needed
    }
};

export const fetchStudentStartupSimStatus = async (studentId) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/Progress`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim status';
        console.error('Error fetching student startup sim status:', message);
        throw new Error(`${message} (Status: ${error.response?.status || 'unknown'})`);
    }
};

export const fetchStudentStartupSimOldProgressData = async (studentId, progressId) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/ProgressData/${progressId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
        });
        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim old progress data';
        console.error('Error fetching student startup sim old progress data:', message);
        throw new Error(`${message} (Status: ${error.response?.status || 'unknown'})`);
    }
};

export const fetchStudentStartupSimResumeData = async (studentId) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/Resume/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
        });
        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim resume data';
        console.error('Error fetching student startup sim resume data:', message);
        throw new Error(`${message} (Status: ${error.response?.status || 'unknown'})`);
    }
};

export const fetchStudentStartupSimAllProgressData = async (studentId) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/AllProgress/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
        });
        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim all progress data';
        console.error('Error fetching student startup sim all progress data:', message);
        throw new Error(`${message} (Status: ${error.response?.status || 'unknown'})`);
    }
};

export const fetchStudentDetails = async (studentId) => {
    try {
        const response = await apiClient(`/api/Students/${studentId}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw { status: response.status, message: errorText };
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching student details:', error.message);
        throw error;
    }
};
