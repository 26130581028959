import { apiClient } from "AuthService";

export const fetchStudentBudgetSimStatus = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/Progress`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim status';
        console.error('Error fetching student startup sim status:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};

export const fetchBudgetSimProgress = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/AllProgress`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim status';
        console.error('Error fetching student startup sim status:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};

export const postBeginBudget = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/Begin/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to begin startup";
        console.error('Error beginning startup:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};

export const postContinueBudget = async (studentId, decision) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/Continue/${decision}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to continue startup";
        console.error('Error continuing startup:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};

export const createClassroomBudgetSim = async (classroomId, configData) => {
    try {
        const response = await apiClient(`/api/BudgetSim/Simulation/Classroom/${classroomId}/Create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to create classroom budget simulation";
        console.error('Error creating classroom budget simulation:', message);
        throw { status: error.response?.status, message };
    }
};

export const joinBudgetSimulation = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/Join`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to join budget simulation";
        console.error('Error joining budget simulation:', message);
        throw { status: error.response?.status, message };
    }
};

export const getNextEvent = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Event/GetNext?studentId=${studentId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        return await response.json();
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to get next event';
        console.error('Error getting next event:', message);
        throw { status: error.response?.status, message };
    }
};

export const getCurrentEvent = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/Event/Current`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch current event';
        console.error('Error fetching current event:', message);
        throw { status: error.response?.status, message };
    }
};

export const getGameStateData = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/GameStateData`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        return await response.json();
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to get game state data';
        console.error('Error getting game state data:', message);
        throw { status: error.response?.status, message };
    }
};

export const getClassroomSimulations = async (classroomId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/Simulations/Classroom/${classroomId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        return await response.json();
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to get classroom simulations';
        console.error('Error getting classroom simulations:', message);
        throw { status: error.response?.status, message };
    }
};

export const submitEventChoice = async (simulationId, studentId, eventId, choiceNumber, accountId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/Event/${eventId}/Choice/${choiceNumber}?accountId=${accountId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to submit event choice';
        console.error('Error submitting event choice:', message);
        throw { status: error.response?.status, message };
    }
};

export const getStudentSimulation = async (classroomId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${classroomId}/player/${studentId}/simulation`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student simulation data';
        console.error('Error fetching student simulation data:', message);
        throw { status: error.response?.status, message };
    }
};

export const advanceSimulation = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/Advance`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to advance simulation";
        console.error('Error advancing simulation:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};

export const getStudentSimulationStatus = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/student/${studentId}/has-active-simulation`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to get student simulation status";
        console.error('Error getting student simulation status:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};

export const getSimulationCalendar = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/Simulations/${simulationId}/Students/${studentId}/Calendar?includeProcessed=true`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch simulation calendar';
        console.error('Error fetching simulation calendar:', message);
        throw { status: error.response?.status, message };
    }
};

export const transferFunds = async (simulationId, studentId, transferData) => {
    try {
        const response = await apiClient(`/api/BudgetSim/Simulations/${simulationId}/Students/${studentId}/Transfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(transferData)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to transfer funds';
        console.error('Error transferring funds:', message);
        throw { status: error.response?.status, message };
    }
};

export const payCreditCard = async (simulationId, studentId, cardId, paymentData) => {
    try {
        const response = await apiClient(`/api/BudgetSim/Simulations/${simulationId}/Students/${studentId}/CreditCards/${cardId}/Pay`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to process credit card payment';
        console.error('Error processing credit card payment:', message);
        throw { status: error.response?.status, message };
    }
};

export const restartPhase = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/RestartPhase`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await response.json();
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to restart phase';
        console.error('Error restarting phase:', message);
        throw { status: error.response?.status, message };
    }
};

export const getPhaseHistory = async (simulationId, studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${simulationId}/Player/${studentId}/PhaseHistory`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });
        return await response.json();
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch phase history';
        console.error('Error fetching phase history:', message);
        throw { status: error.response?.status, message };
    }
};