// src/components/Students.jsx

import React, { useState, useEffect } from "react";
import { Button } from "../../components"; // Ensure these components exist
import { useNavigate } from 'react-router-dom';
import { fetchStudentPortfolioGraph } from "SimulatorAPIService";
import { deleteStudent } from "TeacherAPIService";
import Popup from 'reactjs-popup';  // Ensure reactjs-popup is installed
import LoadingAnimation from "components/LoadingAnimation";
import StudentsTable from "components/ClassroomStudentsTable"; // Ensure correct path
import QuickLink from "components/QuickLink"; // Ensure correct path
import 'reactjs-popup/dist/index.css';

const Students = ({ classroomData }) => {
    const [sortedStudents, setSortedStudents] = useState([]);
    const [loadingBalances, setLoadingBalances] = useState(true);
    const [deletingStudentId, setDeletingStudentId] = useState(null);
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
    const [openAlertPopup, setOpenAlertPopup] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [studentToDelete, setStudentToDelete] = useState(null);
    const [secondConfirmation, setSecondConfirmation] = useState(false);
    const [confirmCheckbox, setConfirmCheckbox] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBalances = async () => {
            const balancePromises = classroomData.students.map(async (student) => {
                try {
                    const portfolioData = await fetchStudentPortfolioGraph(student.id);

                    if (portfolioData.status === 401) {
                        navigate('/timeout');
                        return { ...student, balance: 0 };
                    }

                    return { ...student, balance: portfolioData.currentBalance };
                } catch (error) {
                    console.error(`Failed to fetch balance for student ${student.id}`, error);
                    return { ...student, balance: 0 };
                }
            });

            const updatedStudents = await Promise.all(balancePromises);
            const filteredStudents = updatedStudents.filter((student) => student.balance !== 0);
            setSortedStudents(filteredStudents);
            setLoadingBalances(false);
        };

        fetchBalances();
    }, [classroomData.students, navigate]);


    const handleCreateStudentsClick = () => {
        navigate('/createstudents', { state: { classroomId: classroomData.id } });
    };

    const handleDeleteClick = (student) => {
        console.log('Delete student:', student.id);
        setStudentToDelete(student.id);
        setOpenConfirmPopup(true);
        setConfirmCheckbox(false);
        setSecondConfirmation(false);
    };

    const handleDeleteStudent = async () => {
        if (!studentToDelete || !confirmCheckbox) return;

        try {
            setDeletingStudentId(studentToDelete);
            await deleteStudent(studentToDelete, false);

            // Update the UI
            setSortedStudents(prevStudents =>
                prevStudents.filter(student => student.id !== studentToDelete)
            );

            setOpenConfirmPopup(false);
            setAlertMessage('The student has been successfully removed from the classroom.');
            setOpenAlertPopup(true);

        } catch (error) {
            if (error.response?.status === 500) {
                setSecondConfirmation(true);
                setConfirmCheckbox(false);
            } else {
                setOpenConfirmPopup(false);
                setAlertMessage('Failed to remove student. Please try again.');
                setOpenAlertPopup(true);
            }
        } finally {
            setDeletingStudentId(null);
        }
    };

    const confirmDeleteWithActivity = async () => {
        if (!studentToDelete || !confirmCheckbox) return;

        try {
            setDeletingStudentId(studentToDelete);
            await deleteStudent(studentToDelete, true);

            // Update the UI
            setSortedStudents(prevStudents =>
                prevStudents.filter(student => student.id !== studentToDelete)
            );

            setOpenConfirmPopup(false);
            setAlertMessage('The student and all associated activity data have been successfully removed.');
            setOpenAlertPopup(true);

        } catch (error) {
            setOpenConfirmPopup(false);
            setAlertMessage('Failed to remove student. Please try again.');
            setOpenAlertPopup(true);
        } finally {
            setDeletingStudentId(null);
            setSecondConfirmation(false);
        }
    };

    const handleCancelDelete = () => {
        setOpenConfirmPopup(false);
        setOpenAlertPopup(false);
        setSecondConfirmation(false);
        setConfirmCheckbox(false);
        setStudentToDelete(null);
    };

    return (
        <div className="flex h-full md:overflow-hidden">
            <div className="flex flex-col w-[85%] gap-4 p-4 md:w-full">
                <div className="flex w-full gap-2 justify-between">
                    <div className="flex gap-4">
                        <button
                            onClick={handleCreateStudentsClick}
                            className={`flex justify-center h-[80%] items-center p-4 font-semibold text-blue-500 text-sm border border-blue-500 bg-white rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                        >
                            Create Credentials
                        </button>
                    </div>
                </div>

                <div className="flex flex-col h-full w-full border border-gray-300 rounded-lg overflow-hidden">
                    <div className="h-full overflow-auto">
                        {loadingBalances ? (
                            <div className="h-full flex justify-center items-center overflow-hidden">
                                <LoadingAnimation />
                            </div>
                        ) : (
                            <StudentsTable
                                students={sortedStudents}
                                onDeleteClick={handleDeleteClick}
                                disabled={deletingStudentId !== null}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="flex-col md:w-[33%] lg:w-[25%] h-full p-4 hidden lg:flex">
                <QuickLink classroomData={classroomData} />
            </div>

            {/* Confirmation Popup for Deletion */}
            <Popup open={openConfirmPopup} onClose={handleCancelDelete} modal>
                <div className="modal flex flex-col m-6 items-left justify-center gap-6 rounded-lg">
                    <div className="font-bold text-2xl text-blue-500">
                        {secondConfirmation ? '\u2757 Confirm Removal' : 'Remove Student From Classroom?'}
                    </div>

                    <div className="content">
                        {!secondConfirmation && (
                            <div className="flex flex-col text-left gap-2">
                                <h2 className="text-md text-red-500"> You will <span className="font-semibold underline">not</span> be able to undo this action.</h2>
                                <h2 className="text-sm italic">This does not delete the student's account, it only removes the student from the classroom.</h2>
                                <div className="flex items-center gap-4 mt-2">
                                    <input
                                        type="checkbox"
                                        id="confirmDelete"
                                        className="w-4 h-4 border-2 border-gray-400 rounded focus:ring-blue-500"
                                        checked={confirmCheckbox}
                                        onChange={(e) => setConfirmCheckbox(e.target.checked)}
                                    />
                                    <label htmlFor="confirmDelete" className="text-sm">
                                        I understand this action cannot be undone
                                    </label>
                                </div>
                            </div>
                        )}

                        {secondConfirmation && (
                            <div className="flex flex-col text-left gap-4">
                                <div className="flex flex-col gap-4">
                                    <h1 className="text-lg font-semibold text-accent">This student has previous activity in this classroom.</h1>
                                    <h2 className="text-md text-red-500"> Are you sure you would like to proceed with removal?</h2>
                                </div>
                                <div className="flex items-center gap-4 mt-2">
                                    <input
                                        type="checkbox"
                                        id="confirmDeleteActivity"
                                        className="w-4 h-4 border-2 border-gray-400 rounded focus:ring-blue-500"
                                        checked={confirmCheckbox}
                                        onChange={(e) => setConfirmCheckbox(e.target.checked)}
                                    />
                                    <label htmlFor="confirmDeleteActivity" className="text-sm">
                                        I understand this will remove all student activity data
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="actions flex items-center justify-center gap-4">
                        <Button
                            onClick={handleCancelDelete}
                            className="button-white !px-6 !p-2"
                            disabled={deletingStudentId !== null}
                        >
                            Cancel
                        </Button>

                        {secondConfirmation ? (
                            <button
                                onClick={confirmDeleteWithActivity}
                                className="button-blue hover:bg-red-600 !hover:text-white !px-6 !p-2"
                                disabled={!confirmCheckbox || deletingStudentId !== null}
                            >
                                Yes
                            </button>
                        ) : (
                            <button
                                onClick={handleDeleteStudent}
                                className="button-blue hover:bg-red-500 !hover:text-white-A700 !px-6 !p-2"
                                disabled={!confirmCheckbox || deletingStudentId !== null}
                            >
                                Yes
                            </button>
                        )}

                    </div>
                </div>
            </Popup>

            {/* Alert Popup */}
            <Popup open={openAlertPopup} onClose={handleCancelDelete} modal>
                <div className="modal flex flex-col m-6 items-left justify-center gap-6 rounded-lg">
                    <div className="font-bold text-2xl">
                        {alertMessage.includes('Failed') ? 'Error' : 'Deletion Successful'}
                    </div>
                    <div className="content">
                        {alertMessage}
                    </div>
                    <div className="actions flex items-center justify-center gap-4">
                        <Button
                            onClick={handleCancelDelete}
                            className="btn min-w-[64px] min-h-[32px] max-h-[64px] rounded-lg bg-transparent text-blue-500 border-2 border-blue-500 hover:border-blue-400 hover:text-blue-400"
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default Students;
