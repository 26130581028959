import { apiClient } from "AuthService";

export const fetchStudentInfo = async () => {
    const response = await apiClient('/api/manage/info', {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch student info (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const joinClass = async (classCode) => {
    try {
        const response = await apiClient(`/api/Students/Join/${classCode}`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
                errorData.message || `Error joining class (Status: ${response.status})`
            );
        }


    } catch (error) {
        console.error('Error joining class:', error);
        throw error;
    }
};

export const fetchCourses = async () => {
    try {
        const response = await apiClient('/api/Students', {
            method: 'GET',
            headers: {
                accept: 'application/json', // Adjust as needed
            },
        });

        if (response.status === 401) {
            // Handle unauthorized access
            window.location.href = '/timeout';
            return null;
        }

        if (!response.ok) {
            throw new Error('Failed to fetch courses');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching courses:', error);
        throw error;
    }
};

export const fetchAssignments = async (studentId) => {
    const response = await apiClient(`/api/Students/${studentId}/Assignments`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch assignments (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

export const submitAnswer = async (sectionId, answerId, studentId) => {
    const response = await apiClient(
        `/api/Courses/Units/Sections/${sectionId}/Answer/${answerId}/Submit/${studentId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify({}), // Adjust body if necessary
        }
    );

    if (!response.ok) {
        throw new Error(`Failed to submit answer (Status: ${response.status})`);
    }

    const data = await response.json();
    return data;
};

// Fetch section details by sectionId
export const fetchSection = async (sectionId) => {
    const response = await apiClient(`/api/Courses/Units/Sections/${sectionId}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch section with id ${sectionId} (Status: ${response.status})`);
    }

    const sectionData = await response.json();
    return sectionData;
};

export const fetchStudentDetails = async (studentId) => {
    try {
        const response = await apiClient(`/api/Students/${studentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch student details (Status: ${response.status})`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching student details:', error);
        throw error;
    }
};


export const fetchStudentStatus = async (studentId, courseId) => {
    try {
        const response = await apiClient(
            `/api/Students/${studentId}/Status?courseId=${courseId}`,
            {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                },
            }
        );

        if (response.status === 401) {
            // Handle unauthorized access
            window.location.href = '/timeout'; // Redirect to the timeout page
            return null;
        }

        if (!response.ok) {
            throw new Error('Failed to fetch student status');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching student status:', error);
        throw error;
    }
};

export const fetchUnitTree = async (unitId, studentId) => {
    try {
    const response = await apiClient(`/api/Courses/Units/${unitId}/tree/${studentId}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch unit tree');
    }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching unit tree:', error);
        throw error;
    }
};

export const fetchUnits = async (courseId) => {
    try {
        const response = await apiClient(`/api/Courses/${courseId}/Units/`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch unit tree');
    }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching unit tree:', error);
        throw error;
    }
};


export const startLesson = async (lessonId) => {
    try {
        const response = await apiClient(`/api/Students/Lesson/${lessonId}`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Error starting the lesson');
        }
    } catch (error) {
        console.error('Error starting the lesson:', error);
        throw error;
    }
};

export const endLesson = async (lessonId, duration) => {
    try {
        const response = await apiClient(`/api/Students/Lesson/${lessonId}/End`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify({ duration }),
        });

        if (!response.ok) {
            throw new Error('Error ending the lesson');
        }
    } catch (error) {
        console.error('Error ending the lesson:', error);
        throw error;
    }
};


export const startQuestion = async (questionId) => {
    try {
        const response = await apiClient(`/api/Students/Question/${questionId}`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Error starting the question');
        }
    } catch (error) {
        console.error('Error starting the question:', error);
        throw error;
    }
};


export const endQuestion = async (questionId) => {
    try {
        const response = await apiClient(`/api/Students/Question/${questionId}/End`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Error ending the question');
        }
    } catch (error) {
        console.error('Error ending the question:', error);
        throw error;
    }
};


export const fetchSectionContent = async (sectionId) => {
    try {
        const response = await apiClient(`/api/Courses/Units/Sections/${sectionId}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch section content');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching section content:', error);
        throw error;
    }
};


export const markCompleted = async (id, studentId, isQuiz = false) => {
    const endpoint = isQuiz
        ? `/api/Courses/Units/Sections/Quizzes/${id}/Complete/${studentId}`
        : `/api/Courses/Units/Sections/Lessons/${id}/Complete/${studentId}`;

    try {
        const response = await apiClient(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                completedUTC: new Date(),
                minutes: 0,
            }),
        });

        if (!response.ok) {
            throw new Error('Error marking as complete');
        }
    } catch (error) {
        console.error('Error marking as complete:', error);
        throw error;
    }
};

  