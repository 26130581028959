import React from "react";

const PhaseResults = ({ results = [] }) => {
  if (!results.length) {
    return (
      <div className="p-6 text-center text-gray-500">
        No phase results available.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">

      {results.map((phase, index) => (
        <div key={index} className="border p-4 rounded-lg shadow-sm bg-gray-50">
          <h3 className="text-xl font-bold text-blue-600">{phase.phase}</h3>
          <p className="text-gray-600">{phase.completionMessage}</p>

          {/* Show only if there are metrics */}
          {phase.metricResults?.length > 0 && (
            <div className="mt-3">
              <h4 className="text-lg font-semibold text-gray-700">Metrics:</h4>
              <ul className="grid grid-cols-2 gap-2">
                {phase.metricResults
                  .filter(
                    (metric) => metric.metricName !== "vehicle_reliability"
                  ) // Exclude vehicle reliability
                  .map((metric, idx) => {
                    const isCreditScore = metric.metricName === "credit_score";
                    const adjustedCurrentValue = isCreditScore
                      ? metric.currentValue // Keep credit score as-is
                      : Math.round(metric.currentValue * 100); // Multiply others by 100

                    const adjustedTargetValue = isCreditScore
                      ? metric.targetValue
                      : Math.round(metric.targetValue * 100);

                    return (
                      <div
                        key={idx}
                        className={`p-2 cursor-default rounded-md border ${
                          metric.isMet
                            ? "bg-green-100 border-green-400 text-green-700"
                            : "bg-red-100 border-red-400 text-red-700"
                        }`}
                      >
                        <h1 className="font-semibold">{metric.metricName.replace(/_/g, " ")}</h1>{" "}
                        <div className="flex flex-col">
                          <p>Final: {adjustedCurrentValue}</p>
                          <p>Goal: {adjustedTargetValue}</p>
                          <p>{metric.isMet ? "✅" : "❌"}</p>
                        </div>
                      </div>
                    );
                  })}
              </ul>
            </div>
          )}

          {/* Show only if there are accounts */}
          {phase.accountResults?.length > 0 && (
            <div className="mt-3">
              <h4 className="text-lg font-semibold text-gray-700">
                Account Balances:
              </h4>
              <ul className="grid grid-cols-2 gap-2">
                {phase.accountResults.map((account, idx) => (
                  <li
                    key={idx}
                    className="p-2 border rounded-md bg-white shadow-sm"
                  >
                    <strong>{account.accountName}</strong>
                    <p className="text-gray-700">
                      Balance: ${account.balance.toFixed(2)}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Status Indicator */}
          <div className="mt-4 text-center">
            {phase.isSuccess && !phase.wasSkipped && (
              <p className="text-green-700 font-semibold">
                ✅ Successfully Completed!
              </p>
            )}
            {phase.isFailure && (
              <p className="text-red-700 font-semibold">
                ❌ Failed to Complete
              </p>
            )}
            {phase.wasSkipped && (
              <p className="text-gray-600 font-semibold">⏭️ Skipped</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhaseResults;
