// src/components/BudgetSimulatorComponents/DashboardTabs/index.jsx
import React, { useMemo } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css"; // Import default styles
import BudgetSimDashboardCalendar from "../DashboardCalendar";
import BudgetSimulatorAccounts from "../Accounts";
import Statements from "../Statements";
import TeacherData from "../TeacherData";

const DashboardTabs = ({
  simulationData = {},
  events = [],
  simulatorId,
  onClose,
}) => {
  const currentDate = simulationData?.currentDate;
  const accounts = simulationData?.playerState?.accounts || [];
  const transactions = simulationData?.playerState?.transactionHistory || [];
  const eventHistory = simulationData?.playerState?.eventHistory || [];

  // Combine calendar events with event history
  const combinedEvents = useMemo(() => {
    const historyEvents = eventHistory.map((event) => {
      // Find the selected choice
      const selectedChoice = event.choices?.find(
        (choice) => choice.isSelectedChoice
      );


      return {
        name: event.name || event.description,
        date: event.occurrenceDate,
        type: event.eventType || "default",
        description: event.description,
        // Use choice amount if available
        amount: selectedChoice?.amount || 0,
        // Combine event impacts and choice impacts
        impacts: [
          ...(event.eventImpacts || []),
          ...(selectedChoice?.choiceImpacts || []),
        ],
        // Include choice details if available
        choice: selectedChoice
          ? {
              name: selectedChoice.name,
              description: selectedChoice.description,
              transactionDescription: selectedChoice.transactionDescription,
            }
          : null,
        category: event.category,
        severity: event.severity,
      };
    });

    // Combine and sort by date
    return [...events, ...historyEvents].sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
  }, [events, eventHistory]);


  return (
    <Tabs
      className="container flex flex-col h-full"
      selectedTabPanelClassName="flex w-full h-full"
    >
      <TabList className="flex w-full border-b border-b-gray-200 mb-4 gap-8 px-6">
        <Tab
          className="text-sm pb-2 cursor-pointer text-black"
          selectedClassName="border-b-2 border-blue-500 text-blue-500"
        >
          Calendar
        </Tab>
        <Tab
          className="text-sm pb-2 cursor-pointer text-black"
          selectedClassName="border-b-2 border-blue-500 text-blue-500"
        >
          Accounts
        </Tab>
        <Tab
          className="text-sm pb-2 cursor-pointer text-black"
          selectedClassName="border-b-2 border-blue-500 text-blue-500"
        >
          Statement
        </Tab>
      </TabList>

      {/* Calendar Tab Panel */}
      <TabPanel>
        <div className="grow">
          <BudgetSimDashboardCalendar
            currentDay={currentDate}
            events={combinedEvents}
          />
        </div>
      </TabPanel>

      {/* Accounts Tab Panel */}
      <TabPanel>
        <BudgetSimulatorAccounts
          accounts={accounts}
          simulatorId={simulatorId}
          onClose={onClose}
        />
      </TabPanel>

      {/* Statement Tab Panel */}
      <TabPanel>
        {/* <Statements transactions={transactions} /> */}
        <TeacherData teacherData={simulationData} showBalances={false} />
      </TabPanel>
    </Tabs>
  );
};

export default DashboardTabs;
