import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { IconChevronDown } from "@tabler/icons-react";
import StudentStockSimulator from "components/StudentStockSimulator";
import StudentStartupSimulator from "components/StudentStartupSimulator";
import {
  fetchStudentDetails,
  fetchStudentProgress,
  fetchAnalyticsData,
} from "TeacherAPIService";
import AveragePerformanceChart from "components/AveragePerformanceChart";
import {
  IconSortAscendingLetters,
  IconSortDescendingLetters,
} from "@tabler/icons-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import TimeCompletionChart from "components/TimeCompletedChart";
import TeacherData from "components/BudgetSimulatorComponents/TeacherData";
import {
  getClassroomSimulations,
  getGameStateData,
  getPhaseHistory,
} from "BudgetSimulatorAPIService";
import LoadingAnimation from "components/LoadingAnimation";
import TeacherStudentPerformance from "components/BudgetSimulatorComponents/TeacherStudentPerformance";

export default function StudentPerformance({ classroomData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [studentDetails, setStudentDetails] = useState(null);
  const [activeSection, setActiveSection] = useState("Courses");
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isStudentLoading, setIsStudentLoading] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [sortedStudents, setSortedStudents] = useState(classroomData.students);
  const [budgetSimId, setBudgetSimId] = useState(null);
  const [budgetSimData, setBudgetSimData] = useState(null);
  const [isBudgetSimLoading, setIsBudgetSimLoading] = useState(false);
  const [isFetchingSimId, setIsFetchingSimId] = useState(true);
  const [phaseHistory, setPhaseHistory] = useState(null);
  // Track the active student via their id.
  const [activeTab, setActiveTab] = useState(
    sortedStudents && sortedStudents.length > 0 ? sortedStudents[0].id : null
  );

  // Fetch Budget Simulator ID
  useEffect(() => {
    const fetchBudgetSimId = async () => {
      setIsFetchingSimId(true);
      try {
        const simulations = await getClassroomSimulations(classroomData.id);
        if (simulations) {
          setBudgetSimId(simulations.id);
        }
      } catch (error) {
        console.error("Error fetching budget simulation ID:", error);
      } finally {
        setIsFetchingSimId(false);
      }
    };
    fetchBudgetSimId();
  }, [classroomData.id]);

  const fetchBudgetSimData = async (studentId) => {
    if (!budgetSimId || !studentId) return;
    setIsBudgetSimLoading(true);
    try {
      const data = await getGameStateData(budgetSimId, studentId);
      setBudgetSimData(data);
      const phaseHist = await getPhaseHistory(budgetSimId, studentId);
      setPhaseHistory(phaseHist);
    } catch (error) {
      console.error("Error fetching budget simulation data:", error);
      setBudgetSimData(null);
    } finally {
      setIsBudgetSimLoading(false);
    }
  };

  const handleSort = () => {
    const sorted = [...sortedStudents].sort((a, b) =>
      isAscending ? a.last.localeCompare(b.last) : b.last.localeCompare(a.last)
    );
    setSortedStudents(sorted);
    setIsAscending(!isAscending);
  };

  const handleOptionClick = async (option) => {
    setSelectedOption(option.course.name);
    setIsOpen(false);
    if (studentDetails) {
      setIsStudentLoading(true);
      const studentId = studentDetails.studentId || studentDetails.id;
      const courseId = option.course.id;
      try {
        await fetchStudentProgress(courseId, studentId);
        const analytics = await fetchAnalyticsData(studentId, courseId);
        const processedAnalyticsData = processAnalyticsData(analytics);
        setAnalyticsData(processedAnalyticsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsStudentLoading(false);
      }
    }
  };

  const handleStudentInfo = useCallback(async (studentId) => {
    setIsStudentLoading(true);
    const details = await fetchStudentDetails(studentId);
    setStudentDetails(details);
    if (details.courses && details.courses.length > 0) {
      setSelectedOption(details.courses[0].course.name);
      await fetchStudentProgress(details.courses[0].course.id, studentId);
      const analytics = await fetchAnalyticsData(
        studentId,
        details.courses[0].course.id
      );
      const processedAnalyticsData = processAnalyticsData(analytics);
      setAnalyticsData(processedAnalyticsData);
    }
    setIsStudentLoading(false);
  }, []);

  const handleActiveSection = async (newSection) => {
    setActiveSection(newSection);
    if (
      newSection === "Courses" &&
      studentDetails &&
      studentDetails.courses.length > 0
    ) {
      const analytics = await fetchAnalyticsData(
        studentDetails.studentId,
        studentDetails.courses[0].course.id
      );
      const processedAnalyticsData = processAnalyticsData(analytics);
      setAnalyticsData(processedAnalyticsData);
    } else if (newSection === "Budget Simulator") {
      await fetchBudgetSimData(studentDetails.studentId);
    }
  };

  // On initial load or when sortedStudents change, load the first student's info.
  useEffect(() => {
    if (sortedStudents && sortedStudents.length > 0) {
      setActiveTab(sortedStudents[0].id);
      handleStudentInfo(sortedStudents[0].id);
    }
  }, [sortedStudents, handleStudentInfo]);

  const getStatus = (progress) => {
    switch (progress) {
      case 0:
        return (
          <span className="text-red-800 font-semibold p-2 border border-red-600 rounded-lg bg-red-400">
            Not Started
          </span>
        );
      case 1:
        return (
          <span className="text-yellow-800 font-semibold p-2 border border-yellow-600 rounded-lg bg-yellow-300">
            In Progress
          </span>
        );
      case 2:
        return (
          <span className="text-green-800 font-semibold p-2 border border-green-600 rounded-lg bg-green-300">
            Completed
          </span>
        );
      default:
        return (
          <span className="text-red-800 font-semibold p-2 border border-red-600 rounded-lg bg-red-300">
            Not Started
          </span>
        );
    }
  };

  const processAnalyticsData = (analytics) => {
    const processedData = analytics.map((unit) => {
      const unitStatus = unit.sections.some(
        (section) => section.progress > 0 && section.progress < 100
      )
        ? 1
        : unit.sections.every((section) => section.progress === 2)
        ? 2
        : 0;
      unit.sections.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      return { ...unit, unitStatus };
    });
    processedData.sort((a, b) => {
      const numA = parseInt(a.unitName);
      const numB = parseInt(b.unitName);
      if (numA !== numB) return numA - numB;
      return a.unitName.localeCompare(b.unitName);
    });
    return processedData;
  };

  const calculateUnitCompletion = (unit) => {
    const totalSections = unit.sections.length;
    if (totalSections === 0) return 0;
    const totalPoints = unit.sections.reduce((acc, section) => {
      if (section.progress === 2) return acc + 100;
      if (section.progress === 1) return acc + 50;
      return acc;
    }, 0);
    return Math.round(totalPoints / totalSections);
  };

  const calculateCourseCompletion = (units) => {
    const totalSections = units.reduce((acc, unit) => acc + unit.sections.length, 0);
    if (totalSections === 0) return 0;
    const totalPoints = units.reduce((acc, unit) => {
      return (
        acc +
        unit.sections.reduce((sectionAcc, section) => {
          if (section.progress === 2) return sectionAcc + 100;
          if (section.progress === 1) return sectionAcc + 50;
          return sectionAcc;
        }, 0)
      );
    }, 0);
    return Math.round(totalPoints / totalSections);
  };

  const roundToTenth = (num) => Math.round(num * 10) / 10;

  const calculateTotalMinutesSpent = (units) => {
    return roundToTenth(
      units.reduce((total, unit) => {
        return (
          total +
          unit.sections.reduce((sectionTime, section) => {
            return sectionTime + section.completedSectionInMinutes;
          }, 0)
        );
      }, 0)
    );
  };

  const getSectionWithMostIncorrectQuestions = (units) => {
    let maxIncorrect = 0;
    let sectionWithMostIncorrect = null;
    units.forEach((unit) => {
      unit.sections.forEach((section) => {
        const incorrectCount = section.missedQuestions.reduce(
          (acc, question) => acc + (!question.correct ? 1 : 0),
          0
        );
        if (incorrectCount > maxIncorrect) {
          maxIncorrect = incorrectCount;
          sectionWithMostIncorrect = {
            unitName: unit.unitName,
            sectionName: section.sectionName,
            incorrectCount: maxIncorrect,
          };
        }
      });
    });
    return sectionWithMostIncorrect;
  };

  const calculateFastestSection = (analyticsData) => {
    let fastestSection = null;
    let fastestTime = Infinity;
    analyticsData.forEach((unit) => {
      unit.sections.forEach((section) => {
        if (
          section.completedSectionInMinutes > 0 &&
          section.completedSectionInMinutes < fastestTime
        ) {
          fastestTime = section.completedSectionInMinutes;
          fastestSection = section.sectionName;
        }
      });
    });
    return fastestSection
      ? { name: fastestSection, time: roundToTenth(fastestTime) }
      : { name: "No section completed", time: 0 };
  };

  // Compute the selected index from sortedStudents based on activeTab
  const selectedIndex = sortedStudents.findIndex(
    (student) => student.id === activeTab
  );

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onSelect={(index) => {
        const student = sortedStudents[index];
        setActiveTab(student.id);
        setActiveSection("Courses");
        handleStudentInfo(student.id);
      }}
      className="flex flex-col h-full w-full"
    >
      <div className="flex w-full h-[70vh] p-4 gap-4">
        {/* Student List */}
        <div className="flex flex-col border min-w-[15%] max-w-[15%] rounded-lg">
          <div className="flex justify-between items-center py-2 px-6 bg-blue-50 text-sm font-medium">
            <h2>STUDENT NAME</h2>
            <button onClick={handleSort} className="text-blue-500">
              {isAscending ? (
                <IconSortDescendingLetters />
              ) : (
                <IconSortAscendingLetters />
              )}
            </button>
          </div>
          <TabList className="h-[75vh] overflow-auto">
            {sortedStudents.map((student) => (
              <Tab
                key={student.id}
                className="flex items-center py-2 px-6 text-sm"
                selectedClassName="bg-blue-100"
              >
                {student.first} {student.last}
              </Tab>
            ))}
          </TabList>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-hidden">
          {sortedStudents.map((student) => (
            <TabPanel key={student.id} className=" w-full flex flex-col">
              {isStudentLoading ? (
                <div className="h-full flex justify-center items-center">
                  <LoadingAnimation />
                </div>
              ) : (
                <Tabs className="flex flex-col w-full h-full">
                  {/* Section Tabs */}
                  <div className="flex w-full justify-between">
                    <TabList className="flex flex-grow justify-end">
                      {[
                        "Courses",
                        "Stock Simulator",
                        "Startup Simulator",
                        "Budget Simulator",
                      ].map((section) => (
                        <Tab
                          key={section}
                          onClick={() => handleActiveSection(section)}
                          className={`px-4 py-2 rounded-full ${
                            activeSection === section
                              ? "bg-blue-50 text-blue-500"
                              : "text-gray-700"
                          } border-gray-300 focus:outline-none`}
                        >
                          {section}
                        </Tab>
                      ))}
                    </TabList>
                  </div>

                  {/* Section Content */}
                  <div className="relative h-[50vh] flex-1 overflow-auto">
                    {/* Courses Tab */}
                    <TabPanel className="p-4">
                      <div className="flex flex-col gap-4 h-[59vh] overflow-auto">
                        <div className="flex gap-2 justify-between">
                          <div className="flex flex-col w-[20%] justify-between gap-2">
                            <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                              <span className="text-md font-semibold">
                                Total Course Completion
                              </span>
                              <span className="font-bold text-6xl">
                                {calculateCourseCompletion(analyticsData)}%
                              </span>
                              <div className="w-full bg-gray-200 rounded-full h-4 2xl:h-6">
                                <div
                                  className="bg-blue-500 h-4 2xl:h-6 rounded-full"
                                  style={{
                                    width: `${calculateCourseCompletion(
                                      analyticsData
                                    )}%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                              <span className="text-md font-semibold">
                                Total Minutes Spent
                              </span>
                              <span className="font-bold text-6xl">
                                {calculateTotalMinutesSpent(analyticsData)}
                              </span>
                              <span className="font-semibold">minutes</span>
                            </div>
                            <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                              <span className="text-md font-semibold">
                                Most Incorrect Questions
                              </span>
                              {getSectionWithMostIncorrectQuestions(analyticsData) ? (
                                <>
                                  <span className="font-bold text-6xl">
                                    {
                                      getSectionWithMostIncorrectQuestions(
                                        analyticsData
                                      ).incorrectCount
                                    }
                                  </span>
                                  <span className="font-semibold">
                                    {
                                      getSectionWithMostIncorrectQuestions(
                                        analyticsData
                                      ).sectionName
                                    }
                                  </span>
                                  <span className="text-sm">
                                    {
                                      getSectionWithMostIncorrectQuestions(
                                        analyticsData
                                      ).unitName
                                    }
                                  </span>
                                </>
                              ) : (
                                <span>No incorrect questions</span>
                              )}
                            </div>
                            <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                              <span className="text-md font-semibold">
                                Fastest Section Completed
                              </span>
                              <span className="font-bold text-6xl">
                                {calculateFastestSection(analyticsData).time}
                              </span>
                              <span className="font-semibold">minutes</span>
                              <span className="font-semibold">
                                {calculateFastestSection(analyticsData).name}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col justify-between gap-2 w-[79%]">
                            <span className="border rounded-lg p-4">
                              <AveragePerformanceChart analyticsData={analyticsData} />
                            </span>
                            <span className="border rounded-lg p-4">
                              <TimeCompletionChart analyticsData={analyticsData} />
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <Accordion
                            allowMultipleExpanded
                            allowZeroExpanded
                            className="border rounded-lg"
                          >
                            {analyticsData.map((unit, unitIndex) => (
                              <AccordionItem
                                key={`unit-${unitIndex}`}
                                uuid={`unit-${unitIndex}`}
                                className="p-2 gap-4 flex flex-col"
                              >
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <AccordionItemState>
                                      {({ expanded }) => (
                                        <div className="flex items-center justify-between px-4 py-2">
                                          <div className="flex items-center gap-2 transition">
                                            <IconChevronDown
                                              size={16}
                                              className={`transform transition-transform duration-200 ${
                                                expanded
                                                  ? "rotate-180"
                                                  : "rotate-0"
                                              }`}
                                            />
                                            <span className="font-medium">
                                              {unit.unitName}
                                            </span>
                                          </div>
                                          <div className="flex items-center gap-2">
                                            <span className="text-sm font-semibold">
                                              {calculateUnitCompletion(unit)}%
                                            </span>
                                            <div className="w-24 bg-gray-200 rounded-full h-2">
                                              <div
                                                className="bg-blue-500 h-2 rounded-full"
                                                style={{
                                                  width: `${calculateUnitCompletion(unit)}%`,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </AccordionItemState>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="bg-gray-200 rounded-lg p-2 gap-4">
                                  <Accordion allowMultipleExpanded allowZeroExpanded>
                                    {unit.sections.map((section, sectionIndex) => (
                                      <AccordionItem
                                        key={`section-${unitIndex}-${sectionIndex}`}
                                        uuid={`section-${unitIndex}-${sectionIndex}`}
                                        className="p-2 flex flex-col border border-gray-300 rounded-lg mb-4 bg-white-A700 gap-4"
                                      >
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            <AccordionItemState>
                                              {({ expanded }) => (
                                                <div
                                                  className="grid grid-cols-4 gap-4 p-2 items-center"
                                                  style={{ gridTemplateColumns: "2fr 1fr 1fr 1fr" }}
                                                >
                                                  <div className="flex items-center gap-2 transition">
                                                    <IconChevronDown
                                                      size={16}
                                                      className={`transform transition-transform duration-200 ${
                                                        expanded ? "rotate-180" : "rotate-0"
                                                      }`}
                                                    />
                                                    <span>{section.sectionName}</span>
                                                  </div>
                                                  <span className="text-center">
                                                    {getStatus(section.progress)}
                                                  </span>
                                                  <span className="text-center">
                                                    {roundToTenth(section.completedSectionInMinutes)} min
                                                  </span>
                                                  <span className="text-center">
                                                    {section.progress > 1
                                                      ? `4/${section.missedQuestions.length < 4 ? 4 : section.missedQuestions.length} Question Attempts`
                                                      : "No Completed Quiz"}
                                                  </span>
                                                </div>
                                              )}
                                            </AccordionItemState>
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="bg-white-A700 rounded-lg">
                                          {/* Incorrect/Correct Questions Tables */}
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    ))}
                                  </Accordion>
                                </AccordionItemPanel>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </div>
                      </div>
                    </TabPanel>
                    {/* Stock Simulator Tab */}
                    <TabPanel className="overflow-auto">
                      <StudentStockSimulator studentId={student.id} />
                    </TabPanel>
                    {/* Startup Simulator Tab */}
                    <TabPanel className="overflow-auto">
                      <StudentStartupSimulator studentId={student.id} />
                    </TabPanel>
                    {/* Budget Simulator Tab */}
                    <TabPanel className="overflow-auto">
                      {isBudgetSimLoading || isFetchingSimId ? (
                        <div className="flex justify-center items-center h-full">
                          <LoadingAnimation />
                        </div>
                      ) : budgetSimData ? (
                        <div className="h-[59vh] overflow-auto">
                          <TeacherStudentPerformance
                            teacherData={budgetSimData}
                            phaseHistory={phaseHistory}
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center items-center h-full text-gray-500">
                          {!isFetchingSimId && studentDetails
                            ? "No budget simulation data available for this student"
                            : "Select a student to view their budget simulation data"}
                        </div>
                      )}
                    </TabPanel>
                  </div>
                </Tabs>
              )}
            </TabPanel>
          ))}
        </div>
      </div>
    </Tabs>
  );
}

// CSS for the spinner
const style = `
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = style;
document.head.appendChild(styleSheet);
