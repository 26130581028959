import React from "react";
import Lottie from "lottie-react";
import phaseChangeAnimation from "assets/animations/celebration.json";

const PhaseChangeComponent = ({ phaseInfo, onClose }) => {
  const gameState = phaseInfo;
  if (!gameState) return null;
  


  const { currentPhase } = gameState.result.completedPhase;

  const formatLifePhase = (phase) => {
    if (typeof phase !== "string") {
      console.error("Invalid phase input:", phase);
      return "Unknown Phase"; // Fallback value
    }
  
    return phase
      .replace(/([A-Z])/g, " $1") // Add spaces before capital letters
      .trim()
      .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize first letter of each word
  };
  

  return (
    <div className="flex flex-col w-full max-h-[80vh] border rounded-lg shadow-md bg-white">
      {/* Header Section */}
      <div className="flex-none p-4 border-b">
        <h2 className="text-2xl font-semibold text-center">
          {gameState.result.messages[0]}
        </h2>
      </div>

      {/* Animation Section */}
      <div className="flex-1 overflow-y-auto">
        <div className="flex justify-center p-4">
          <Lottie
            animationData={phaseChangeAnimation}
            loop={true}
            autoPlay
            className="w-48 h-48"
          />
        </div>

        {/* Phase Information */}
        <div className="p-4">
          <div className="text-center mb-6">
            <p className="text-lg text-gray-700">
              You have completed {formatLifePhase(gameState.result.completedPhase)} and entered a new phase of your financial journey!
            </p>
          </div>

          {/* Metrics Section */}
          {gameState.metrics && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Current Status</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {gameState.metrics.map((metric, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-medium text-gray-700">
                      {metric.title}
                    </h4>
                    <div className="mt-2">
                      <div className="flex justify-between text-sm text-gray-600 mb-1">
                        <span>Current: {Math.round(metric.value * 100)}%</span>
                        <span>
                          Target: {Math.round(metric.targetValue * 100)}%
                        </span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                          className={`h-2 rounded-full ${
                            metric.value >= metric.targetValue
                              ? "bg-green-500"
                              : "bg-yellow-500"
                          }`}
                          style={{
                            width: `${(metric.value / metric.maxValue) * 100}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Warnings Section */}
          {gameState.warnings && gameState.warnings.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Important Notes</h3>
              <div className="space-y-2">
                {gameState.warnings.map((warning, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded ${
                      warning.severity === "High"
                        ? "bg-red-100 text-red-700"
                        : warning.severity === "Medium"
                        ? "bg-yellow-100 text-yellow-700"
                        : "bg-blue-100 text-blue-700"
                    }`}
                  >
                    <p>{warning.message}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Footer Section */}
      <div className="flex p-4 border-t items-center justify-center bg-gray-50">
        <button
          onClick={onClose}
          className="button-blue !py-2 w-[90%]"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default PhaseChangeComponent;
