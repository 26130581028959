import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  fetchStudentId,
  fetchStudentPortfolioGraph,
} from "SimulatorAPIService";
import { fetchStudentStartupSimResumeData } from "SimulatorAPIService";
import { fetchCourses, fetchStudentDetails } from "StudentAPIService";
import {
  getGameStateData,
  getNextEvent,
  submitEventChoice,
  joinBudgetSimulation,
  getStudentSimulation,
  getClassroomSimulations,
  getStudentSimulationStatus,
  getSimulationCalendar,
} from "BudgetSimulatorAPIService";
import { useUser } from "UserContext";
import StudentSidebar from "components/StudentSidebar";
import HomeDate from "components/HomeDate";
import DashboardQuote from "components/DashboardQuote";
import DashboardCourses from "components/DashboardCourses";
import DashboardCalendar from "components/DashboardCalendar";
import DashboardStartupSimWidget from "components/DashboardStartupSimWidget";
import DashboardBudgetSimWidget from "components/DashboardBudgetSimWidget";
import DashboardStockSimWidget from "components/DashboardStockSimWidget";
import LoadingAnimation from "components/LoadingAnimation";

export default function StudentDashboardPage() {
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const [courses, setCourses] = useState([]);
  const [studentId, setStudentId] = useState();

  const [portfolioGraph, setPortfolioGraph] = useState([]);
  const [startupSimDashData, setStartupSimDashData] = useState({});

  const [simulationData, setSimulationData] = useState(null);
  const [simulationId, setSimulationId] = useState(null);

  // Main loading state for the entire dashboard (except portfolio)
  const [isLoading, setIsLoading] = useState(true);
  // Separate loading state for the portfolio chart
  const [isPortfolioLoading, setIsPortfolioLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMainData = async () => {
      try {
        const id = await fetchStudentId();
        setStudentId(id);

        if (id) {
          // Fetch all data except the portfolio graph
          const [coursesData, startupSimData] = await Promise.all([
            fetchCourses(),
            fetchStudentStartupSimResumeData(id),
          ]);

          // Process courses
          if (coursesData) {
            setCourses(
              coursesData.flatMap((s) =>
                s.courses.map((c) => ({
                  studentId: s.studentId,
                  course: c.course,
                  class: c.class,
                }))
              )
            );
          }

          setStartupSimDashData(startupSimData?.progress);
        }
      } catch (error) {
        if (error?.status === 401) {
          navigate("/timeout");
        } else {
          console.error("Error fetching main data:", error);
        }
      } finally {
        // Main data is loaded, so we can show the dashboard content
        setIsLoading(false);
      }
    };

    fetchMainData();
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First get the correct student ID
        const studentId = userInfo.studentId;

        //first check to see if the student has joined a simulation
        const simStatus = await getStudentSimulationStatus(studentId);

        if (!simStatus.hasActiveSimulation) {
          return;
        }

        const studentDetails = await fetchStudentDetails(studentId);
        const classroomId = studentDetails.courses[0].class.id;

        const studentSimData = await getStudentSimulation(
          classroomId,
          studentId
        );

        setSimulationId(studentSimData);

        const gameStateData = await getGameStateData(studentSimData, studentId);
        setSimulationData(gameStateData);
      } catch (error) {
        console.error("Error in fetchData:", error);
        if (error.message?.includes("UserProvider")) {
          console.error("UserContext not properly initialized");
        }
      }
    };
    fetchData();
  }, [userInfo]);

  // Separate effect to load the portfolio graph after we have the studentId and main data
  useEffect(() => {
    const fetchPortfolio = async () => {
      if (!studentId) return;

      try {
        const portfolioData = await fetchStudentPortfolioGraph(studentId);
        setPortfolioGraph(portfolioData);
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      } finally {
        setIsPortfolioLoading(false);
      }
    };

    if (!isLoading && studentId) {
      fetchPortfolio();
    }
  }, [isLoading, studentId]);

  const handleViewAllCourses = () => {
    navigate("/courses");
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Intertwined</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>

      <div className="relative w-full h-screen bg-mesh-gradient">
        <div className="flex w-full h-screen overflow-hidden m-auto">
          {/* Sidebar */}
          <div>
            <StudentSidebar />
          </div>

          {/* Main Content */}
          <div className="flex flex-col w-full h-screen p-4 gap-4">
            {isLoading ? (
              // Loading animation for the main dashboard data
              <div className="flex items-center justify-center w-full h-full">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                <HomeDate className={"w-full px-4 rounded-lg shadow-md"} />

                <div className="flex flex-col w-full h-full gap-4 overflow-y-auto">
                  <div className="flex flex-col sm:flex-row w-full gap-4">
                    <div className="flex flex-col gap-4 w-full sm:w-1/2 xl:w-2/3">
                      <DashboardQuote />

                      <DashboardCourses
                        studentId={studentId}
                        courses={courses}
                        handleViewAllCourses={handleViewAllCourses}
                      />
                    </div>

                    <div className="flex flex-col h-full w-full sm:w-1/2 xl:w-1/3 gap-4">
                      <div className="h-full w-full shadow-md rounded-lg">
                        {studentId && (
                          <DashboardCalendar studentId={studentId} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full h-full gap-4">
                    <div className="flex flex-col w-1/2 gap-4 h-full">
                      <DashboardStartupSimWidget
                        marketValue={startupSimDashData?.currentMarketValue}
                        employeeWellness={startupSimDashData?.employeeWellness}
                        grossRevenue={startupSimDashData?.grossRevenue}
                        profitLoss={startupSimDashData?.profitLoss}
                      />

                      {isLoading ? (
                        <div className="animate-pulse bg-white rounded-lg shadow-md p-6">
                          <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                        </div>
                      ) : error ? (
                        <div className="bg-white rounded-lg shadow-md p-6">
                          <p className="text-red-500">{error}</p>
                        </div>
                      ) : simulationData ? (
                        <DashboardBudgetSimWidget
                          checkingAccount={simulationData.playerState?.accounts?.find(m => m.accountName === "Checking Account")?.balance || 0}
                          savingsAccount={simulationData.playerState?.accounts?.find(m => m.accountName === "Savings Account")?.balance || 0}
                          creditCard={simulationData.playerState?.accounts?.find(m => m.accountName === "Credit Card")?.balance || 0}
                          phase={simulationData.currentPhase || 'N/A'}
                        />
                      ) : (
                        <DashboardBudgetSimWidget
                          checkingAccount={0}
                          savingsAccount={0}
                          creditCard={0}
                          phase={'N/A'}
                        />
                      )}
                    </div>

                    {/* Portfolio Chart Section with its own loading state */}
                    <div className="flex w-1/2 h-full shadow-md rounded-lg">
                      <DashboardStockSimWidget
                        portfolioGraph={portfolioGraph}
                        loading={isPortfolioLoading}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
