import React from "react";
import Lottie from "lottie-react";
import successAnimation from "assets/animations/succeed.json"; // Replace with an actual animation if available

const GameComplete = ({ phaseInfo, onClose }) => {
  return (
    <div className="flex flex-col w-full max-h-[80vh] gap-4 border rounded-lg shadow-md bg-white-A700 justify-center items-center">
      {/* Header Section */}
      <div className="flex w-full items-center justify-center p-4 border-b bg-green-600 rounded-t-lg">
        <h2 className="text-3xl font-bold text-white-A700 text-center">
          Congratulations!
        </h2>
      </div>

      {/* Animation Section */}
      <div className="flex justify-center p-6">
        <Lottie 
          animationData={successAnimation} 
          loop={false} 
          autoPlay 
          className="w-48 h-48"
        />
      </div>

      {/* Message Section */}
      <div className="text-center px-6">
        <h3 className="text-xl font-semibold text-gray-700">
          You've successfully completed the game! Great work.
        </h3>
        <p className="text-gray-600 mt-2">
          {phaseInfo?.message || "Your journey through financial literacy has been a success. Well done!"}
        </p>
      </div>

      {/* Footer Section */}
      <div className="p-4 border-t w-full flex justify-center">
        <button
          onClick={onClose}
          className="px-6 py-2 bg-green-600 text-white-A700 rounded hover:bg-green-700 transition-colors hover:scale-105"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default GameComplete;
