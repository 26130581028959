import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { addCourseToClassroom, fetchCourses } from 'TeacherAPIService';
import { IconX, IconSearch, IconCheck } from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";
import 'reactjs-popup/dist/index.css';

const AddCourseModal = ({ open, onClose, classId, classroomData, reloadClassroomDetails }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [coursesData, setCoursesData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCourses();
        setCoursesData(data);
      } catch (error) {
        setError(error);
        if(error.status === 401) {
          navigate("/timeout");
        }
      } finally {
        
      }
    };

    fetchData();
  }, [navigate]);

  const filteredCourses = coursesData.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddCourse = async (courseId) => {
    try {
      await addCourseToClassroom(classId, courseId);
      reloadClassroomDetails(); // Reload classroom details after adding the course
      onClose();  // Close the modal after adding the course
    } catch (error) {
      console.error('Failed to add course', error);
    }
  };

  if (error) {
    return <p>Error fetching courses: {error.message}</p>;
  }

  return (
    <Popup open={open} closeOnDocumentClick onClose={onClose} modal contentStyle={{ width: '60%', borderRadius: '1rem', height: '650px' }}>
      <div className="flex flex-col items-center h-full p-4 gap-4 overflow-hidden">
        <div className='flex flex-col items-center w-full '>
          <button className='text-red-500 w-full text-right' onClick={onClose}>
            <p className='flex w-full justify-end items-center'><span><IconX className='h-6 w-6'/></span> Cancel</p>
          </button>
          <div className='items-center'>
            <h1 className='text-center text-2xl font-bold'>Add a course to your class</h1>
          </div>
        </div>
        <h2 className='text-lg'>Choose from any of our available courses below</h2>
        <div className='flex gap-3 items-start border border-gray-300 w-full p-3 rounded-lg'>
          <span className='text-gray-400'><IconSearch /></span>
          <input
            type="text"
            placeholder="Search by course name, level..."
            className="w-full p-2 border rounded placeholder-gray-400 items-center justify-center"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full rounded-lg overflow-auto gap-4">
          {filteredCourses.map(course => (
            <div key={course.id} className="flex justify-between items-center p-4 bg-gray-200 rounded-lg">
              <div className='w-[85%]'>
                <h3 className="text-xl font-semibold">{course.name}</h3>
                <p className="text-gray-600">{course.description}</p>
              </div>
              {classroomData.courses.some(c => c.id === course.id) ? (
                <IconCheck className="text-green-500 h-8 w-8" />
              ) : (
                <button className="bg-blue-500 text-white-A700 p-4 rounded-lg" onClick={() => handleAddCourse(course.id)}>Add Course</button>
              )}
            </div>
          ))}
        </div>
      </div>
    </Popup>
  );
};

export default AddCourseModal;
