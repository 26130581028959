// src/components/PayCreditCardModal.jsx
import React, { useState } from "react";
import { formatUSD } from "utils"; // Ensure this utility function is correctly imported
import { payCreditCard } from "BudgetSimulatorAPIService";
import { useUser } from "UserContext";

const PayCreditCardModal = ({ onClose, creditCard, accounts, simulatorId }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userInfo } = useUser();

  // Filter out the credit card from payment source options
  const paymentSources = accounts.filter(
    (acc) => acc.accountId !== creditCard.accountId
  );

  // Define payment options
  const paymentOptions = [
    {
      label: "Minimum Payment",
      value: creditCard.minimumPayment,
    },
    {
      label: "Current Balance",
      value: creditCard.Balance * -1,
    },
    {
      label: "Other Amount",
      value: null,
    },
  ];

  // Handler for selecting a payment option
  const handleOptionSelect = (option) => {
    setSelectedOption(option.label);
    if (option.value !== null) {
      setPaymentAmount(option.value.toString());
    } else {
      setPaymentAmount("");
    }
  };

  // Handler for amount changes
  const handlePaymentChange = (e) => {
    setPaymentAmount(e.target.value);
    setError("");
  };

  // Handler for account selection
  const handleAccountSelect = (e) => {
    setSelectedAccountId(e.target.value);
    setError("");
  };

  const selectedAccount = paymentSources.find(
    (acc) => acc.accountId === selectedAccountId
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!selectedOption) {
      setError("Please select a payment option.");
      return;
    }

    if (!selectedAccountId) {
      setError("Please select an account to pay from.");
      return;
    }

    const amount = parseFloat(paymentAmount);
    if (isNaN(amount) || amount <= 0) {
      setError("Please enter a valid payment amount.");
      return;
    }

    const creditCardBalance = Math.abs(creditCard.Balance); // Ensure positive balance

    if (amount > creditCardBalance) {
      setError(
        `Payment cannot exceed the current balance of ${formatUSD(
          creditCardBalance
        )}.`
      );
      return;
    }

    if (!selectedAccount || amount > selectedAccount.Balance) {
      setError("Insufficient funds in the selected account.");
      return;
    }

    try {
      setIsSubmitting(true);
      await payCreditCard(
        simulatorId,
        userInfo.studentId,
        creditCard.accountId,
        {
          sourceAccountId: selectedAccountId,
          amount: amount,
        }
      );

      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Credit card payment failed:", error);
      setError(error.message || "Payment failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col p-6 gap-4 rounded-lg w-full">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl font-semibold">Pay Credit Card</h1>
        <p className="text-gray-600">Manage your credit card payments below.</p>
      </div>

      {/* Credit Card Details */}
      <div className="flex flex-col gap-2 mb-6">
        <div className="flex justify-between">
          <span className="font-medium text-gray-700">Current Balance:</span>
          <span className="text-red-500">
            {formatUSD(creditCard.Balance * -1)}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="font-medium text-gray-700">
            Minimum Payment Due:
          </span>
          <span className="text-red-500">
            {formatUSD(creditCard.minimumPayment)}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="font-medium text-gray-700">Due Date:</span>
          <span className="text-gray-700">{creditCard.dueDate}</span>
        </div>
      </div>

      {/* Account Selection */}
      <div className="flex flex-col gap-2 mb-6">
        <label htmlFor="account-select" className="font-medium text-gray-700">
          Pay from Account:
        </label>
        <div className="border rounded-lg">
          <select
            id="account-select"
            value={selectedAccountId}
            onChange={handleAccountSelect}
            className="w-full p-2 marker:rounded-md"
            required
          >
            <option value="">Select an account</option>
            {paymentSources.map((acc) => (
              <option key={acc.accountId} value={acc.accountId}>
                {acc.accountName}:{" "}
                <span className="font-bold">{formatUSD(acc.balance)}</span>
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Payment Options */}
      <div className="flex flex-col gap-2 mb-6">
        <span className="font-medium text-gray-700 mb-2">
          Select Payment Option:
        </span>
        {paymentOptions.map((option, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handleOptionSelect(option)}
            className={`p-2 border rounded-md text-left ${
              selectedOption === option.label
                ? "bg-blue-500 text-white-A700"
                : "bg-white-A700 text-blue-500 hover:bg-blue-100"
            } transition-colors duration-200`}
          >
            {option.label}{" "}
            {option.value !== null && `(${formatUSD(option.value)})`}
          </button>
        ))}
      </div>

      {/* Payment Form */}
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        {/* Payment Amount Input */}
        <div className="flex flex-col">
          <label
            htmlFor="payment-amount"
            className="mb-2 font-medium text-gray-700"
          >
            Payment Amount:
          </label>
          <div className="p-4 rounded-lg border">
            <input
              type="number"
              step="0.01"
              id="payment-amount"
              value={paymentAmount}
              onChange={handlePaymentChange}
              placeholder="Enter amount to pay"
              className={`p-2 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400 ${
                selectedOption !== "Other Amount"
                  ? "bg-gray-100 cursor-not-allowed"
                  : ""
              }`}
              min="0"
              required
              disabled={selectedOption !== "Other Amount"}
            />
          </div>
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500">{error}</p>}

        {/* Buttons */}
        <div className="flex w-full gap-4">
          <button
            type="button"
            onClick={onClose}
            className={`flex w-[30%] justify-center items-center p-4 font-inter font-semibold text-blue-500 text-md border border-blue-500 bg-white rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
          >
            Back
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`flex w-[70%] justify-center items-center p-4 font-inter font-semibold bg-blue-500 text-white-A700 text-md border rounded-lg hover:bg-blue-400 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100 ${
              isSubmitting ? "opacity-75 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? (
              <div className="h-6 w-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
            ) : (
              "Pay"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PayCreditCardModal;
