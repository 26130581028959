import React, { useState, useEffect } from "react";
import { formatUSD } from "utils";

const sessionBalancesTitles = {
  checkingAccount: {
    title: "Checking Account",
    img: "/images/img_bank.svg",
  },
  savingsAccount: {
    title: "Savings Account",
    img: "/images/img_bank.svg",
  },
  creditCard: {
    title: "Credit Card",
    img: "/images/img_credit_card_02.svg",
  },
  lifePhase: {
    title: "Current Phase",
    img: "/images/img_bank_note_01.svg",
  },
};

const formatLifePhase = (phase) => {
  return phase
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/\b\w/g, (c) => c.toUpperCase());
};

const BalancesBar = ({ gameState }) => {
  const [accountBalances, setAccountBalances] = useState({
    checkingAccount: 0,
    savingsAccount: 0,
    creditCard: 0,
    lifePhase: "",
  });

  useEffect(() => {
    if (gameState?.playerState?.accounts) {
      const accounts = gameState.playerState.accounts;

      const checking =
        accounts.find((m) => m.accountName === "Checking Account")?.balance ||
        0;
      const savings =
        accounts.find((m) => m.accountName === "Savings Account")?.balance || 0;
      const creditCard =
        accounts.find((m) => m.accountName === "Credit Card")?.balance || 0;
      const currentPhase = gameState.currentPhase || "";

      setAccountBalances({
        checkingAccount: checking,
        savingsAccount: savings,
        creditCard: creditCard,
        lifePhase: currentPhase,
      });
    }
  }, [gameState]);

  return (
    <div className="flex  w-full h-20 rounded-lg bg-white-A700 border divide-x divide-gray-200 font-inter">
      {Object.entries(sessionBalancesTitles).map(([key, value]) => (
        <div key={key} className="flex w-full items-center p-4 gap-4">
          <h1 className="flex items-center gap-4">
            <span>
              <img
                src={value.img}
                alt={value.title}
                className="h-10 w-10 p-2 rounded-full bg-slate-100 hidden md:block"
              />
            </span>
          </h1>
          <div>
            <h2
              className={
                key === "lifePhase"
                  ? "text-blue-600"
                  : `${
                      accountBalances[key] < 0
                        ? "text-red-600"
                        : "text-green-600"
                    }`
              }
            >
              {key === "lifePhase"
                ? formatLifePhase(accountBalances[key])
                : key === "creditCard" &&
                  accountBalances.lifePhase === "HighSchoolStudent"
                ? "Available in next phase"
                : formatUSD(accountBalances[key])}
            </h2>

            {value.title}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BalancesBar;
