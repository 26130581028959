import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // Optional: For interactions
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css"; // Make sure the CSS is imported
import { formatUSD } from "utils"; // Adjust the path to your utils file if needed

// Renders individual events
const EventContent = ({ event }) => {
  const {
    type,
    amount,
    impacts = [],
    choice,
    description,
    category,
    severity,
  } = event.extendedProps;

  const styles = {
    "Random Life Event": {
      wrapper: "bg-purple-50 border-l-4 border-purple-500 p-1 truncate",
      title: "text-purple-700 font-semibold text-xs",
      amount: "text-purple-600 text-xs",
      icon: "🎯",
    },
    income: {
      wrapper: "bg-green-50 border-l-4 border-green-500 p-1 truncate",
      title: "text-green-700 font-semibold text-xs",
      amount: "text-green-600 text-xs",
      icon: "💰",
    },
    expense: {
      wrapper: "bg-red-50 border-l-4 border-red-500 p-1 truncate",
      title: "text-red-700 font-semibold text-xs",
      amount: "text-red-600 text-xs",
      icon: "💳",
    },
    statement: {
      wrapper: "bg-blue-50 border-l-4 border-blue-500 p-1 truncate",
      title: "text-blue-700 font-semibold text-xs",
      amount: "text-blue-600 text-xs",
      icon: "📄",
    },
    event: {
      wrapper: "bg-purple-50 border-l-4 border-purple-500 p-1 truncate",
      title: "text-purple-700 font-semibold text-xs",
      amount: "text-purple-600 text-xs",
      icon: "🎯",
    },
    interest: {
      wrapper: "bg-yellow-50 border-l-4 border-yellow-500 p-1 truncate",
      title: "text-yellow-700 font-semibold text-xs",
      amount: "text-yellow-600 text-xs",
      icon: "📈",
    },
    default: {
      wrapper: "bg-gray-50 border-l-4 border-gray-500 p-1 truncate",
      title: "text-gray-700 font-semibold text-xs",
      amount: "text-gray-600 text-xs",
      icon: "📅",
    },
  };

  const style = styles[type] || styles.default;

  // Create tooltip content
  const tooltipContent = [
    event.title,
    amount !== 0 && `Amount: ${formatUSD(amount)}`,
    choice && `Choice: ${choice}`,
    impacts?.length > 0 && "Impacts:",
    ...impacts?.map(
      (impact) => `${impact.metricName}: ${(impact.value * 100).toFixed(0)}%`
    ),
  ]
    .filter(Boolean)
    .join("\n");

  return (
    <div
      className={style.wrapper}
      data-tooltip-id={`event-${event.id}`}
      data-tooltip-content={tooltipContent}
    >
      <div className="flex items-center gap-1">
        <span>{style.icon}</span>
        <div className="flex flex-col">
          <div className={style.title}>{event.title}</div>
          {amount !== 0 && (
            <div className={style.amount}>{formatUSD(amount)}</div>
          )}
        </div>
      </div>
      <ReactTooltip
        id={`event-${event.id}`}
        place="top"
        content={tooltipContent}
        style={{
          whiteSpace: "pre-line",
          maxWidth: "300px",
        }}
      />
    </div>
  );
};

const BudgetSimDashboardCalendar = ({ events = [], currentDay }) => {
  // Transform incoming events to FullCalendar's expected format
  const transformEvents = (apiEvents) => {
    return apiEvents.map((event) => {
      const eventType = event.type?.toLowerCase() || "default";
      let type = "default";

      if (eventType.includes("payday") || eventType.includes("income")) {
        type = "income";
      } else if (eventType.includes("expense") || eventType.includes("bill")) {
        type = "expense";
      } else if (eventType.includes("statement")) {
        type = "statement";
      } else if (eventType.includes("paymentdue")) {
        type = "expense";
      } else if (eventType.includes("random life event")) {
        type = "Random Life Event";
      } else if (eventType.includes("interest")) {
        type = "interest";
      }

      return {
        title: event.name,
        date: event.date,
        extendedProps: {
          type,
          amount: event.amount || 0,
          description: event.description,
          category: event.category,
          severity: event.severity,
          choice: event.choice,
          impacts: event.impacts,
        },
      };
    });
  };

  // When the mouse enters an event, attach tooltip attributes
  const handleMouseEnter = (info) => {
    const description = info.event.extendedProps.description;
    if (description) {
      info.el.setAttribute("data-tooltip-id", "calendar-tooltip");
      info.el.setAttribute("data-tooltip-content", description);
    }
  };

  // When the mouse leaves, remove the tooltip attributes
  const handleMouseLeave = (info) => {
    info.el.removeAttribute("data-tooltip-id");
    info.el.removeAttribute("data-tooltip-content");
  };

  return (
    <div className="grow h-full w-full p-2 relative">
      <FullCalendar
        titleFormat={{ year: "numeric", month: "long" }}
        key={currentDay} // Forces a remount when currentDay changes
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={transformEvents(events)}
        eventContent={(arg) => <EventContent event={arg.event} />}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek",
        }}
        height="100%"
        nowIndicator={true}
        now={currentDay}
        eventMouseEnter={handleMouseEnter}
        eventMouseLeave={handleMouseLeave}
      />
      {/* Render react-tooltip which will attach to any element with data-tooltip-id="calendar-tooltip" */}
      <ReactTooltip id="calendar-tooltip" place="top" variant="dark" />
    </div>
  );
};

export default BudgetSimDashboardCalendar;
