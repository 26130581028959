import { apiClient } from "AuthService";

export const updateUserProfile = async (data) => {
    try {
      const response = await apiClient('/api/UserInfo/Edit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        // Handle errors returned from the server
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update profile.');
      }
  
      // Update was successful
      const successData = await response.json();
      return successData;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  };

export const getUserInfo = async () => {
    try {
        const response = await apiClient('/api/UserInfo', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to fetch user info";
        console.error('Error fetching user info:', message);
        throw new Error(JSON.stringify({ status: error.response?.status, message }));
    }
};