import React, { useState } from "react";
import { formatUSD } from "utils";
import Lottie from "lottie-react";
import paydayAnimation from "assets/animations/payday.json";
import expenseAnimation from "assets/animations/expense.json";
import creditCardAnimation from "assets/animations/creditcard.json";

const TransactionComponent = ({ transactionInfo, onClose, warnings = [] }) => {
  // Extract event types while ensuring uniqueness
  const uniqueEventTypes = [...new Set(
    Array.isArray(transactionInfo.eventTypes) ? transactionInfo.eventTypes : [transactionInfo.eventType]
  )];

  // Group transactions by type
  const deposits = (transactionInfo.transactions || []).filter(t => t.type === "Deposit");
  const withdrawals = (transactionInfo.transactions || []).filter(t => t.type === "Withdrawal");
  const creditCardTransactions = (transactionInfo.transactions || []).filter(t => t.accountId?.includes("credit-card"));

  // Define available tabs while avoiding duplicate "Expense"
  const tabs = [...new Set(uniqueEventTypes)].map(type => ({
    label: type === "Payday" ? "Payday" : 
           type === "Expense" ? "Expenses" :
           type === "CreditCardPayment" ? "Credit Card" : type,
    key: type
  }));

  // Set initial tab (first event type)
  const [activeTab, setActiveTab] = useState(tabs[0]?.key || "");

  return (
    <div className="flex flex-col w-full max-h-[95vh] border rounded-lg shadow-md bg-white-A700">
      {/* Header Section */}
      <div className="flex w-full items-center justify-center p-4 border-b">
        <h2 className="text-2xl font-semibold text-center">
          Transactions Overview
        </h2>
      </div>

      {/* Warnings Section */}
      {warnings.length > 0 && (
        <div className="flex-none p-4 w-full items-center justify-center">
          {warnings.map((warning, index) => (
            <p key={index} className="text-red-600 text-sm text-center w-full">{warning}</p>
          ))}
        </div>
      )}

      {/* Tab Navigation */}
      <div className="flex w-full border-b">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`flex-1 text-center py-2 font-semibold transition-colors ${
              activeTab === tab.key ? "border-b-4 border-blue-500 text-blue-600" : "text-gray-400 hover:text-gray-700"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Content Section */}
      <div className="flex-1 overflow-y-hidden p-2">
        {activeTab === "Payday" && (
          <TabContent title="Payday" animation={paydayAnimation} transactions={deposits} messages={transactionInfo.messages} positive />
        )}
        {activeTab === "Expense" && (
          <TabContent title="Expenses" animation={expenseAnimation} transactions={withdrawals} messages={transactionInfo.messages} />
        )}
        {activeTab === "CreditCardPayment" && (
          <TabContent title="Credit Card Charges" animation={creditCardAnimation} transactions={creditCardTransactions} messages={transactionInfo.messages} />
        )}
      </div>

      {/* Footer Section */}
      <div className="flex p-4 border-t w-full items-center justify-center">
        <button onClick={onClose} className="w-[90%] button-blue !py-2">
          Continue
        </button>
      </div>
    </div>
  );
};

/**
 * Tab Content Component
 */
const TabContent = ({ title, animation, transactions, messages, positive = false }) => {
  // Filter messages based on event type
  const filteredMessages = messages?.filter((message) => {
    if (title === "Payday") return message.startsWith("Received");
    if (title === "Expenses") return message.startsWith("Paid");
    return true; // Show all messages for other event types
  });

  return (
    <div className="flex flex-col items-center gap-4">
      <Lottie animationData={animation} loop={true} autoPlay className="w-48 h-48" />

      {/* Display only filtered messages */}
      {filteredMessages && filteredMessages.length > 0 && (
        <div className="flex flex-col gap-2 px-4 py-2">
          {filteredMessages.map((message, index) => (
            <div key={index} className="text-center break-words">
              {message}
            </div>
          ))}
        </div>
      )}

      <div className="w-full overflow-y-auto h-[20vh]">
        {transactions.length > 0 ? (
          transactions.map((transaction, index) => (
            <div key={`transaction-${transaction.id || transaction.description}-${index}`} className="flex flex-col items-center py-2">
              <p className={`text-2xl font-semibold ${positive ? "text-green-600" : "text-red-600"}`}>
                {positive ? formatUSD(transaction.amount) : `-${formatUSD(Math.abs(transaction.amount))}`}
              </p>
              <p className="uppercase text-gray-600 font-semibold italic text-center break-words">
                {transaction.description}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-400 text-center">No transactions found.</p>
        )}
      </div>
    </div>
  );
};


export default TransactionComponent;
