import React from 'react';
import { formatDate } from 'utils';
import Lottie from "lottie-react";
import warningAnimation from "assets/animations/stop.json";

const WarningPopup = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black-900 bg-opacity-50">
      <div className="bg-white-A700 rounded-lg shadow-xl max-w-lg w-full mx-4">
        {/* Header */}
        <div className="p-4 border-b">
          <h2 className="text-2xl font-bold text-center text-red-600">
            Warning
          </h2>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="flex justify-center mb-4">
            <Lottie
              animationData={warningAnimation}
              loop={true}
              autoPlay
              className="w-32 h-32"
            />
          </div>

          {/* Notification */}
          {data.notification && (
            <div className="mb-4 text-center text-red-600 font-medium">
              {data.notification}
            </div>
          )}

          {/* Warnings */}
          {data.warnings && data.warnings.length > 0 && (
            <div className="mb-4">
              <div className="space-y-2">
                {data.warnings.map((warning, index) => (
                  <div
                    key={index}
                    className="p-3 bg-red-100 text-red-700 rounded"
                  >
                    {warning}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Game Failure */}
          {data.isGameFailed && (
            <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-lg">
              <h3 className="text-lg font-semibold text-red-700 mb-2">
                Game Over
              </h3>
              <p className="text-red-600 mb-2">
                Reason: {data.failureReason}
              </p>
              <p className="text-gray-600">
                Date: {formatDate(data.failureDate)}
              </p>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-4 border-t bg-gray-50 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-red-600 text-white-A700 rounded hover:bg-red-700 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
