import React from "react";
import Popup from 'reactjs-popup';
import StartupSimProgress from "components/StartupSimProgress";
import 'reactjs-popup/dist/index.css';

export default function StartupSimProgressPopup({ open, onClose, progressChange, setShowPopup, finishedStage, stages, stageNumber, investments, studentId, setData }) {

  return (
    <Popup open={open} onClose={onClose} modal contentStyle={{ width: '400px', borderRadius: '1rem' }}>
      <StartupSimProgress progressChange={progressChange} setShowPopup={setShowPopup} finishedStage={finishedStage} stages={stages} stageNumber={stageNumber} investments={investments} studentId={studentId} setData={setData} />
    </Popup>
  );
}
