import React, { useState } from 'react';
import { formatDate } from 'utils';
import Lottie from "lottie-react";
import failureAnimation from "assets/animations/fail.json";
import { restartPhase } from 'BudgetSimulatorAPIService';

// Mapping of metric Names to Titles
const metricTitleMap = {
  "credit_score": "Credit Score",
  "stress": "Stress",
  "health": "Health",
  "social_life": "Social Life",
};

const FailurePopup = ({ isOpen, onClose, data, simulationId, studentId }) => {
  const [isRestarting, setIsRestarting] = useState(false);

  if (!isOpen) return null;

  const handleRestart = async () => {
    try {
      setIsRestarting(true);
      const response = await restartPhase(simulationId, studentId);
      if (response.success) {
        window.location.reload();
      } else {
        console.error('Failed to restart phase:', response);
        setIsRestarting(false);
      }
    } catch (error) {
      console.error('Failed to restart phase:', error);
      setIsRestarting(false);
    }
  };

  const handleClose = () => {
    setIsRestarting(false);
    onClose();
  };

  // Ensure the failure reason uses the correct Title
  const failureReasonTitle = metricTitleMap[data.failureReason] || data.failureReason;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black-900 bg-opacity-50">
      <div className="bg-white-A700 rounded-lg shadow-xl max-w-lg w-full mx-4">
        <div className="p-4 border-b rounded-t-lg bg-red-600">
          <h2 className="text-3xl font-bold text-center text-white-A700">
            Game Over
          </h2>
        </div>

        <div className="p-6">
          <div className="flex justify-center mb-6">
            <Lottie
              animationData={failureAnimation}
              loop={true}
              autoPlay
              className="w-48 h-48"
            />
          </div>

          <div className="mb-6 text-center">
            <h3 className="text-xl font-semibold text-red-600 mb-2">
              {failureReasonTitle} {/* ✅ Uses the correct title */}
            </h3>
            <p className="text-gray-600">
              Game ended on {formatDate(data.failureDate)}
            </p>
          </div>
        </div>

        <div className="p-4 border-t bg-gray-50 flex justify-end gap-3 rounded-b-lg">
          <button
            onClick={handleClose}
            className="px-6 py-2 bg-gray-600 text-white-A700 rounded hover:bg-gray-700 transition-colors"
          >
            Close
          </button>
          <button
            onClick={handleRestart}
            disabled={isRestarting}
            className="px-6 py-2 bg-red-600 text-white-A700 rounded hover:bg-red-700 transition-colors disabled:bg-red-400 disabled:cursor-not-allowed flex items-center gap-2"
          >
            {isRestarting ? (
              <>
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Restarting...
              </>
            ) : (
              'Restart Phase'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FailurePopup;
