import React, { useEffect } from "react";
import { formatUSD } from "utils";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import TransferMoneyModal from "../TransferMoneyModal";
import PayCreditCardModal from "../PayCreditCardModal";

const getAccountType = (account) => {
  if (account.Name.toLowerCase().includes("credit")) return "credit_card";
  if (account.Name.toLowerCase().includes("savings")) return "savings";
  return "checking";
};

const typeDisplayNames = {
  checking: "CHECKING ACCOUNT",
  savings: "SAVINGS ACCOUNT",
  credit_card: "CREDIT CARD",
};

const AccountComponent = ({ account, accounts, simulatorId, onClose }) => {
  const accountType = getAccountType(account);
  const displayName =
    typeDisplayNames[accountType] || account.Name.toUpperCase();
  const balanceLabel =
    accountType === "credit_card" ? "Current Balance" : "Available Balance";
  const buttonText =
    accountType === "credit_card" ? "Pay Credit Card" : "Transfer Money";

  // Calculate total deposits and withdrawals
  const deposits = account.Transactions.filter(
    (t) => t.Type === "Deposit"
  ).reduce((sum, t) => sum + t.Amount, 0);

  const withdrawals = account.Transactions.filter(
    (t) => t.Type === "Withdrawal" || t.Type === "Charge"
  ).reduce((sum, t) => sum + Math.abs(t.Amount), 0);


  return (
    <div className="flex flex-col font-inter divide-y justify-start first-letter:w-full rounded-lg border">
      <div className="p-4 flex justify-between items-center">
        <h1 className="text-gray-700 text-sm">{displayName}</h1>
        <span className="text-sm text-gray-600">{account.BankName}</span>
      </div>

      <div className="flex w-full justify-between">
        <div className="flex flex-col w-full p-4">
          <h2 className="text-xl font-semibold">
            {formatUSD(account.Balance)}
          </h2>
          <p className="text-sm">{balanceLabel}</p>
          {account.Name === "Credit Card" && (
            <p className="text-xs text-gray-600">
              Credit Limit: {formatUSD(account.CreditLimit)}
            </p>
          )}
          {account.InterestRate > 0 && (
            <p className="text-xs text-gray-600">
              {account.InterestType === "Earning" ? "Earning" : "APR"}:{" "}
              {(account.InterestRate * 100).toFixed(2)}% {account.InterestType === "Earning" ? "APY" : ""}
            </p>
          )}
        </div>

        <div className="flex w-full justify-between gap-2 p-4">
          <div className="flex flex-col gap-2">
            <h2 className="text-green-600">{formatUSD(deposits)}</h2>
            <p className="text-sm">Deposits</p>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-red-600">{formatUSD(withdrawals)}</h2>
            <p className="text-sm">
              {accountType === "credit_card" ? "Charges" : "Withdrawals"}
            </p>
          </div>
        </div>

        <div className="flex w-full justify-end items-center p-4">
          <Popup
            trigger={
              <button className="flex justify-center h-[80%] items-center p-4 font-inter font-semibold text-blue-500 text-md border border-blue-500 bg-white rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100">
                {buttonText}
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal rounded-lg">
                {account.Name === "Credit Card" ? (
                  <PayCreditCardModal
                    onClose={() => {
                      close();
                      onClose();
                    }}
                    creditCard={account}
                    accounts={accounts}
                    simulatorId={simulatorId}
                  />
                ) : (
                  <TransferMoneyModal
                    onClose={() => {
                      close();
                      onClose();
                    }}
                    account={account}
                    accounts={accounts}
                    simulatorId={simulatorId}
                  />
                )}
              </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default AccountComponent;
