import React, { useEffect, useState } from "react";
import { IconAlertCircle } from "@tabler/icons-react";
import { fetchStatus, postSettings } from "StartupSimAPIService";
import { useParams } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";

export default function StartupSimSettings() {
  const { classroomID } = useParams();
  const [selectedDifficulty, setSelectedDifficulty] = useState("easy");
  const [currentMarketValueFail, setCurrentMarketValueFail] = useState("");
  const [employeeWellnessFail, setEmployeeWellnessFail] = useState("");
  const [grossRevenueFail, setGrossRevenueFail] = useState("");
  const [profitLossFail, setProfitLossFail] = useState("");
  const [difficultySet, setDifficultySet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promiseResult = await fetchStatus(classroomID);
        setDifficultySet(promiseResult?.optionsSet || false);

        if (promiseResult?.options) {
          setSelectedDifficulty(
            promiseResult?.options.difficulty.charAt(0).toUpperCase() +
              promiseResult?.options.difficulty.slice(1)
          );
          setCurrentMarketValueFail(
            promiseResult?.options.currentMarketValueFail
          );
          setEmployeeWellnessFail(promiseResult?.options.employeeWellnessFail);
          setGrossRevenueFail(promiseResult?.options.grossRevenueFail);
          setProfitLossFail(promiseResult?.options.profitLossFail);
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [classroomID]);

  const validateInputs = () => {
    const errors = {};

    if (
      !["easy", "medium", "hard"].includes(selectedDifficulty.toLowerCase())
    ) {
      errors.selectedDifficulty = "Invalid difficulty selected.";
    }
    if (!/^-?\d+(\.\d{1,2})?$/.test(currentMarketValueFail)) {
      errors.currentMarketValueFail =
        "Enter a valid number for Market Value Fail.";
    }

    if (
      !/^\d+(\.\d{1,2})?$/.test(employeeWellnessFail) ||
      employeeWellnessFail < 0 ||
      employeeWellnessFail > 100
    ) {
      errors.employeeWellnessFail =
        "Enter a number between 0 and 100 for Employee Wellness Fail.";
    }

    if (!/^-?\d+(\.\d{1,2})?$/.test(grossRevenueFail)) {
      errors.grossRevenueFail = "Enter a valid number for Gross Revenue Fail.";
    }

    if (!/^-?\d+(\.\d{1,2})?$/.test(profitLossFail)) {
      errors.profitLossFail = "Enter a valid number for Profit/Loss Fail.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^-?\d*\.?\d*$/; // Allows positive/negative decimal numbers

    if (name !== "selectedDifficulty" && !numericRegex.test(value)) return;

    switch (name) {
      case "currentMarketValueFail":
        setCurrentMarketValueFail(value);
        break;
      case "employeeWellnessFail":
        setEmployeeWellnessFail(value);
        break;
      case "grossRevenueFail":
        setGrossRevenueFail(value);
        break;
      case "profitLossFail":
        setProfitLossFail(value);
        break;
      default:
        break;
    }
  };

  const handleUpdateSettings = async () => {
    if (!validateInputs()) {
      return;
    }

    setIsSubmitting(true);

    const settingData = {
      difficulty: selectedDifficulty.toLowerCase(),
      currentMarketValueFail: parseFloat(currentMarketValueFail),
      employeeWellnessFail: parseInt(employeeWellnessFail, 10),
      grossRevenueFail: parseFloat(grossRevenueFail),
      profitLossFail: parseFloat(profitLossFail),
    };

    try {
      await postSettings(classroomID, settingData);
      setDifficultySet(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error posting settings:", error);
      setErrorMessage("Failed to update settings. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-white-A700 rounded-lg">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4 gap-6 bg-white-A700 rounded-lg h-full w-full shadow-md text-accent">
      <h1 className="text-3xl font-semibold">Startup Simulator Settings</h1>
      <p className="text-sm text-gray-600">
        For a description of the different difficulties, visit our{" "}
        <a
          target="_blank"
          className="text-blue-400 hover:underline"
          href="https://help.intertwinedfinance.com/en/articles/10223251-business-startup-simulator-101"
          rel="noreferrer"
        >
          Support Site
        </a>
        .
      </p>

      {!isLoading && (
        <div className="flex flex-col gap-6 text-left w-1/2">
          {!difficultySet ? (
            <div className="flex flex-col border rounded-lg divide-y">
              <div className="flex justify-between p-4 items-center">
                <label>Difficulty</label>
                <div className="border">
                  <select
                    className="w-40 p-2 font-semibold rounded-lg"
                    value={selectedDifficulty}
                    onChange={(e) => setSelectedDifficulty(e.target.value)}
                  >
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>
              </div>

              {[
                {
                  name: "currentMarketValueFail",
                  label: "Market Value Fail Point",
                  description: "Student starts at $20,000.",
                },
                {
                  name: "employeeWellnessFail",
                  label: "Employee Wellness Fail Point",
                  description:
                    "Student starts at 65 out of 100. Enter a number between 0 and 100.",
                },
                {
                  name: "grossRevenueFail",
                  label: "Gross Revenue Fail Point",
                  description: "Student starts at $10,000",
                },
                {
                  name: "profitLossFail",
                  label: "Profit/Loss Fail Point",
                  description: "Student starts at -$5,000",
                },
              ].map(({ name, label, description }) => (
                <div
                  key={name}
                  className="flex justify-between p-4 items-center"
                >
                  <div className="w-1/2">
                    <label>{label}</label>
                    <p className="text-xs text-gray-400">{description}</p>
                    {validationErrors[name] && (
                      <span className="text-red-500 text-xs">
                        {validationErrors[name]}
                      </span>
                    )}
                  </div>
                  <div className="border rounded-lg p-2">
                    {name !== "employeeWellnessFail" && (
                      <span className="text-gray-400">$ </span>
                    )}
                    <input
                      className="font-semibold w-24"
                      required
                      type="text"
                      name={name}
                      value={eval(name)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col border rounded-lg divide-y">
              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Difficulty</p>
                <p className="font-semibold">{selectedDifficulty}</p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Current Market Value Fail Point</p>
                <p className="font-semibold">
                  {currentMarketValueFail < 0
                    ? `-$${Math.abs(currentMarketValueFail).toLocaleString()}`
                    : `$${Number(currentMarketValueFail).toLocaleString()}`}
                </p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Employee Wellness Fail Point</p>
                <p className="font-semibold">{employeeWellnessFail}/100</p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Gross Revenue Fail Point</p>
                <p className="font-semibold">
                  {grossRevenueFail < 0
                    ? `-$${Math.abs(grossRevenueFail).toLocaleString()}`
                    : `$${Number(grossRevenueFail).toLocaleString()}`}
                </p>
              </div>

              <div className="flex justify-between p-4 hover:bg-gray-100">
                <p>Profit/Loss Fail Point</p>
                <p className="font-semibold">
                  {profitLossFail < 0
                    ? `-$${Math.abs(profitLossFail).toLocaleString()}`
                    : `$${Number(profitLossFail).toLocaleString()}`}
                </p>
              </div>
            </div>
          )}

          {!difficultySet && (
            <button
              className="button-blue w-full mt-4"
              onClick={handleUpdateSettings}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Set Options"}
            </button>
          )}

          {errorMessage && (
            <div className="text-red-600 mt-4">{errorMessage}</div>
          )}
        </div>
      )}
    </div>
  );
}
