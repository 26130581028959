import React, { useRef, useEffect, useState } from "react";
import { Button, Img, Text, Heading } from "./..";
import { joinClass } from "StudentAPIService";

const JoinClassPopup = ({ onClose, onJoin }) => {
  const popupRef = useRef();

  const [classCode, setClassCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleBackClick = () => {
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message

    if (!classCode) {
      setError("Please enter a code");
      return;
    }

    if (classCode.length > 9 || classCode.length < 8) {
      setError("Please enter a 9-character class code");
      return;
    }

    try {
      await joinClass(classCode);
      setSuccess(true);
      onJoin && onJoin();
    } catch (error) {
      console.error(error);
      setError(
        error.message ||
          "Error joining class. Make sure you have entered the right code!"
      );
    }
  };

  return (
    <div
      ref={popupRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "30%",
          height: "auto",
          maxHeight: "60%",
          minWidth: "30%",
          minHeight: "40%",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          overflowY: "auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        <button
          onClick={handleBackClick}
          style={{
            position: "absolute",
            top: "8px",
            right: "16px",
            background: "none",
            border: "none",
            fontSize: "24px",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        <div
          style={{ textAlign: "center", padding: "15px", overflow: "hidden" }}
        >
          <div style={{ position: "relative", margin: "", width: "100%" }}>
            <div
              style={{
                backgroundColor: "#4FAAFF",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "",
              }}
            >
              <Img
                src="images/img_avatars_3d_avatar_26.png"
                alt="avatars3d"
                style={{
                  height: "116px",
                  width: "116px",
                  objectFit: "cover",
                  margin: "0 auto",
                  marginBottom: "20px",
                }}
              />
              <Heading
                as="h5"
                style={{
                  fontSize: "22px",
                  marginBottom: "20px",
                  color: "white",
                }}
              >
                Join a Class
              </Heading>
              <Text
                size="lg"
                as="p"
                style={{
                  textAlign: "center",
                  color: "white",
                  marginBottom: "30px",
                  lineHeight: "1.5",
                  fontSize: "11px",
                }}
              >
                Enter a class code to join. <br /> Don't have a code? Contact
                your teacher.
              </Text>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col w-[100%] gap-4">
              <div className="text-red-500 text-sm">
                {error !== "" ? error : ""}
              </div>

              {success && (
                <div className="flex flex-col gap-4 items-center">
                  <div className="w-full text-center">
                    <h2 className="text-green-600">
                      Successfully Joined Class
                    </h2>
                  </div>

                  <Button
                    type="button"
                    size="5xl"
                    variant="outline"
                    shape="round"
                    className="min-w-[161px] gap-2 font-semibold shadow-sm sm:px-5"
                    onClick={(e) => {
                      setClassCode("");
                      setError("");
                      setSuccess(false);
                      onClose && onClose(e);
                    }}
                  >
                    Close
                  </Button>
                </div>
              )}

              {!success && (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-[5px] text-left">
                    <Text
                      as="p"
                      className="!font-medium !text-blue_gray-800_01"
                    >
                      Enter Class Code <span className="text-red-500">*</span>
                    </Text>
                    <div className="border rounded-lg px-2">
                      <input
                        type="text"
                        placeholder="eg. AB12-WX3Y"
                        id="code"
                        name="code"
                        value={classCode}
                        onChange={(e) => setClassCode(e.target.value)}
                        className="h-[48px] rounded-lg border  w-full text-primary placeholder:text-gray-400 outline-none"
                      />
                    </div>
                  </div>

                  <div className="flex gap-8 justify-between">
                    <Button
                      type="button"
                      size="5xl"
                      variant="outline"
                      shape="round"
                      leftIcon={
                        <Img
                          src="images/img_arrowdown.svg"
                          alt="arrow_down"
                          className="h-[16px] w-[16px]"
                        />
                      }
                      className="min-w-[161px] gap-2 font-semibold shadow-sm sm:px-5"
                      onClick={(e) => {
                        setClassCode("");
                        setError("");
                        setSuccess(false);
                        onClose && onClose(e);
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      size="5xl"
                      shape="round"
                      className="border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                    >
                      Submit Request
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinClassPopup;
