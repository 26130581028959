import React, { useEffect, useState, useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import StudentSidebar from "components/StudentSidebar";
import { useNavigate } from "react-router-dom";
import BalancesBar from "components/BudgetSimulatorComponents/BalancesBar";
import { formatDate } from "utils";
import LoadingAnimation from "components/LoadingAnimation";
import DashboardTabs from "components/BudgetSimulatorComponents/DashboardTabs";
import DashboardGaugeChart from "components/BudgetSimulatorComponents/DashboardGaugeChart";
import MonthlySpendingChart from "components/BudgetSimulatorComponents/MonthlySpendingChart";
import OptionsComponent from "components/BudgetSimulatorComponents/OptionsComponent";
import UpcomingEvents from "components/BudgetSimulatorComponents/UpcomingEvents";
import RecentTransactions from "components/BudgetSimulatorComponents/RecentTransactions";
import WarningPopup from "components/BudgetSimulatorComponents/WarningPopup";
import FailurePopup from "components/BudgetSimulatorComponents/FailurePopup";
import {
  getGameStateData,
  getNextEvent,
  submitEventChoice,
  joinBudgetSimulation,
  getStudentSimulation,
  getClassroomSimulations,
  getStudentSimulationStatus,
  getSimulationCalendar,
} from "BudgetSimulatorAPIService";
import { fetchStudentId, fetchStudentDetails } from "SimulatorAPIService";
import { useUser } from "UserContext";

export default function BudgetSimulatorDashboard() {
  const navigate = useNavigate();
  const { userInfo, updateUserInfo } = useUser();
  const [canStart, setCanStart] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [simulationData, setSimulationData] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [events, setEvents] = useState([]);
  const [simulationId, setSimulationId] = useState(null);
  const [warningData, setWarningData] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [failureData, setFailureData] = useState(null);
  const [showFailure, setShowFailure] = useState(false);

  const [calendarEvents, setCalendarEvents] = useState(() => {
    return JSON.parse(localStorage.getItem("calendarEvents")) || [];
  });

  //   const creditScoreMetric = fakesimulationData.PlayerState.Metrics.find(
  //     (metric) => metric.Name === "credit_score"
  //   );

  //   const stressMetric = fakesimulationData.PlayerState.Metrics.find(
  //     (metric) => metric.Name === "stress"
  //   );

  const getNthWeekdayOfMonth = (year, month, weekday, nth) => {
    const firstDayOfMonth = new Date(year, month, 1);
    const firstWeekday = new Date(
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() +
          ((weekday - firstDayOfMonth.getDay() + 7) % 7)
      )
    );
    return new Date(
      firstWeekday.setDate(firstWeekday.getDate() + (nth - 1) * 7)
    );
  };

  const adjustForWeekday = (date) => {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6) {
      date.setDate(date.getDate() + 2);
    } else if (dayOfWeek === 0) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  };

  const generateRecurringEvents = (currentDay) => {
    const events = [];
    let today = new Date(currentDay);

    if (isNaN(today.getTime())) {
      console.warn("Invalid currentDay:", currentDay);
      today = new Date("2024-11-27T14:30:00+00:00");
    }

    const year = today.getFullYear();

    for (let month = 0; month < 12; month++) {
      events.push(
        {
          title: "Rent",
          start: adjustForWeekday(new Date(year, month, 1))
            .toISOString()
            .slice(0, 10),
          description: "Monthly rent payment",
          classNames: [
            "bg-red-500 border border-red-500 text-white-A700 font-inter font-semibold",
          ],
        },
        {
          title: "Payday",
          start: getNthWeekdayOfMonth(year, month, 5, 1)
            .toISOString()
            .slice(0, 10),
          description: "First payday of the month",
          classNames: [
            "bg-green-500 border border-green-500 text-white-A700 font-inter font-semibold",
          ],
        },
        {
          title: "Payday",
          start: getNthWeekdayOfMonth(year, month, 5, 3)
            .toISOString()
            .slice(0, 10),
          description: "Second payday of the month",
          classNames: [
            "bg-green-500 border border-green-500 text-white-A700 font-inter font-semibold",
          ],
        },
        {
          title: "Utilities",
          start: adjustForWeekday(new Date(year, month, 10))
            .toISOString()
            .slice(0, 10),
          description: "Monthly utilities bill",
          classNames: [
            "bg-orange-500 border border-orange-500 text-white-A700 font-inter font-semibold",
          ],
        },
        {
          title: "Groceries",
          start: new Date(year, month, 15).toISOString().slice(0, 10),
          description: "Budget for monthly groceries",
          classNames: [
            "bg-purple-500 border border-purple-500 text-white-A700 font-inter font-semibold",
          ],
        }
      );
    }

    return events;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First get the correct student ID
        const studentId = userInfo.studentId;

        //first check to see if the student has joined a simulation
        const simStatus = await getStudentSimulationStatus(studentId);
        console.log(simStatus);
        if (!simStatus.hasActiveSimulation) {
          navigate("start");
          return;
        }

        const studentDetails = await fetchStudentDetails(studentId);
        const classroomId = studentDetails.courses[0].class.id;

        const studentSimData = await getStudentSimulation(
          classroomId,
          studentId
        );

        setSimulationId(studentSimData);

        if (calendarEvents.length === 0) {
          const fetchCalendarEvents = async () => {
            const events = await getSimulationCalendar(
              studentSimData,
              userInfo.studentId
            );
            setCalendarEvents(events);
          };
          fetchCalendarEvents();
          localStorage.setItem("calendarEvents", JSON.stringify(events));
        }

        const gameStateData = await getGameStateData(studentSimData, studentId);
        setSimulationData(gameStateData);
      } catch (error) {
        console.error("Error in fetchData:", error);
        if (error.message?.includes("UserProvider")) {
          console.error("UserContext not properly initialized");
        }
      }
    };
    fetchData();
  }, []);

  const handleAdvanceResponse = (response) => {

    // If not a failure but has warnings, show warning popup
    if (response.warnings?.length > 0) {
      setWarningData(response);
      setShowWarning(true);
    }
  };

  const handleEventChoice = async (eventId, choiceNumber, accountId) => {
    try {
      setIsSubmitting(true);
      const response = await submitEventChoice(
        simulationId,
        userInfo.studentId,
        eventId,
        choiceNumber,
        accountId
      );

      handleAdvanceResponse(response);

      // Update game state after choice
      const updatedGameState = await getGameStateData(
        simulationId,
        userInfo.studentId
      );
      setSimulationData(updatedGameState);

      // Close the popup after successful submission
      setIsPopupOpen(false);
    } catch (error) {
      console.error("Error submitting choice:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNextDay = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = async () => {
    try {
      // Update game state when popup is closed
      const updatedGameState = await getGameStateData(
        simulationId,
        userInfo.studentId
      );
      setSimulationData(updatedGameState);
    } catch (error) {
      console.error("Error updating game state:", error);
    }
    setIsPopupOpen(false);
  };

  const handlePopupSubmit = async (selectedOptionIndex, eventId, accountId) => {
    setIsSubmitting(true);
    try {
      await handleEventChoice(eventId, selectedOptionIndex, accountId);
    } catch (error) {
      console.error(
        "Error submitting data or fetching updated progress",
        error
      );
    } finally {
      setIsSubmitting(false);
      setIsPopupOpen(false);
    }
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
    setWarningData(null);
  };

  const handleCloseFailure = () => {
    setShowFailure(false);
    setFailureData(null);
  };

  return (
    <>
      <Helmet>
        <title>Intertwined Finance | Budget Simulator</title>
        <meta name="description" content="Intertwined Finance" />
      </Helmet>
      <div className="flex w-full h-screen overflow-hidden">
        <StudentSidebar />

        {loading || !simulationData ? (
          <LoadingAnimation />
        ) : (
          <div className="flex flex-col w-full p-6 gap-2 items-center justify-center">
            <BalancesBar gameState={simulationData} />

            <div className="flex h-full w-full bg-white-A700 divide-x rounded-lg border border-gray-200">
              <div className="flex flex-col w-[70%] h-full">
                <div className="flex justify-between p-4">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-2">
                      <h1 className="text-2xl font-semibold">
                        Budget Simulator
                      </h1>
                      <div className=" flex items-center justify-center">
                        <span className="text-[12px] font-semibold px-2 rounded-full bg-blue-200 text-black-900">
                          BETA
                        </span>
                      </div>
                    </div>
                    <h2 className="text-gray-600 text-md">
                      {formatDate(simulationData.currentDate)}
                    </h2>
                  </div>

                  <button
                    onClick={handleNextDay}
                    className={`flex justify-center h-full items-center p-4 font-inter font-semibold text-white-A700 text-md border border-gray-200 bg-blue-500 rounded-lg hover:bg-blue-400 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="h-6 w-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    ) : (
                      "Advance"
                    )}
                  </button>
                </div>

                <div className="flex h-full w-full">
                  <DashboardTabs
                    simulationData={simulationData}
                    events={calendarEvents.events}
                    simulatorId={simulationId}
                    onClose={handlePopupClose}
                  />
                </div>

                {/* Disclaimer */}
                <div className="w-full text-center py-2 text-xs text-gray-400 border-t">
                  Intertwined AI can make mistakes. Check important info.
                </div>
              </div>

              <div className="flex flex-col w-[30%] p-4 gap-4">
                <div className="grid grid-cols-1 gap-4 ">
                  <div className="flex flex-col gap-4">
                    {simulationData?.playerState?.metrics
                      .filter((metric) => metric.name !== "vehicle_reliability")
                      .map((metric) => (
                        <DashboardGaugeChart
                          key={metric.name}
                          metric={metric}
                        />
                      ))}
                  </div>

                  {/* Recent Transactions */}
                  <RecentTransactions
                    accounts={simulationData?.playerState?.accounts || []}
                  />

                  {/* <MonthlySpendingChart gameState={simulationData} /> */}
                </div>
              </div>
            </div>

            {isPopupOpen && (
              <div className="fixed inset-0 z-40 flex items-center justify-center bg-black-900 bg-opacity-50">
                <div className="relative w-full max-w-4xl mx-4 bg-white-A700 rounded-lg shadow-xl">
                  <OptionsComponent
                    simulationId={simulationId}
                    studentId={userInfo.studentId}
                    onSubmit={handlePopupSubmit}
                    onClose={handlePopupClose}
                    accounts={simulationData?.playerState?.accounts || []}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </div>
            )}

            <WarningPopup
              isOpen={showWarning}
              onClose={handleCloseWarning}
              data={warningData || {}}
            />
          </div>
        )}
      </div>
    </>
  );
}
