// src/components/TransferMoneyModal.jsx
import React, { useState, useEffect, useContext } from "react";
import { formatUSD } from "utils";
import { transferFunds } from "BudgetSimulatorAPIService";
import { useUser } from "UserContext";

const sampleData = {
  user: {
    id: "user_12345",
    name: "Jane Doe",
    email: "jane.doe@example.com",
    createdAt: "2020-05-15T10:30:00Z",
  },
  accounts: [
    {
      id: "acc_001",
      type: "checking",
      nickname: "Daily Expenses",
      accountNumber: "****1234",
      balance: 1500.75,
      availableBalance: 1500.75,
      currency: "USD",
      interestRate: 0.01,
      createdAt: "2020-05-15T10:30:00Z",
      earnings: 50.0,
    },
    {
      id: "acc_002",
      type: "savings",
      nickname: "Emergency Fund",
      accountNumber: "****5678",
      balance: 10000.0,
      availableBalance: 10000.0,
      currency: "USD",
      interestRate: 0.02,
      createdAt: "2019-03-20T09:00:00Z",
      earnings: 200.0,
    },
    {
      id: "acc_003",
      type: "credit_card",
      nickname: "Visa Platinum",
      accountNumber: "****9012",
      balance: -235.0,
      creditLimit: 5000.0,
      availableCredit: 4765.0,
      currency: "USD",
      interestRate: 0.18,
      createdAt: "2021-07-10T12:00:00Z",
      dueDate: "2024-12-05T00:00:00Z",
      minimumPayment: 35.0,
      earnings: 0.0,
    },
  ],
  currentDate: "2024-11-27T14:30:00+00:00",
};

const typeDisplayNames = {
  checking: "Checking Account",
  savings: "Savings Account",
  // 'credit_card': 'Credit Card', // Removed since credit cards are excluded
};

const TransferMoneyModal = ({ onClose, accounts, account, simulatorId }) => {
  const [data, setData] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(account.accountId);
  const [transferToAccountID, setTransferToAccountID] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [noteToSelf, setNoteToSelf] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userInfo, updateUserInfo } = useUser();

  useEffect(() => {
    // Simulate fetching data from an API
    // In a real scenario, replace this with an actual API call
    // Filter out credit_card accounts
    const filteredAccounts = accounts.filter(
      (acc) =>
        acc.accountName.toLowerCase() !== "credit card" &&
        acc.accountId !== selectedAccountId
    );
    setData({ ...sampleData, accounts: filteredAccounts });
    if (filteredAccounts.length > 0) {
      setTransferToAccountID(filteredAccounts[0].accountId); // Default to the first account for transfer to
    }
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleTransferTo = (e) => {
    setTransferToAccountID(e.target.value);
    setError("");
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    // Regular expression to allow only numbers with up to 2 decimal places
    const validCurrencyRegex = /^\d+(\.\d{0,2})?$/;

    if (inputValue === "" || validCurrencyRegex.test(inputValue)) {
      setTransferAmount(inputValue);
      setError("");
    }
  };

  const handleNoteToSelf = (e) => {
    setNoteToSelf(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fromAccount = account;
    const toAccount = data.accounts.find(
      (acc) => acc.accountId === transferToAccountID
    );

    if (!fromAccount) {
      setError("Please select an account to transfer from");
      return;
    }

    if (!toAccount) {
      setError("Please select an account to transfer to");
      return;
    }

    if (
      !transferAmount ||
      isNaN(transferAmount) ||
      parseFloat(transferAmount) <= 0
    ) {
      setError("Please enter a valid transfer amount");
      return;
    }

    const amount = parseFloat(transferAmount);

    if (amount > fromAccount.Balance) {
      setError("Insufficient funds for transfer");
      return;
    }

    try {
      setIsSubmitting(true);
      await transferFunds(simulatorId, userInfo.studentId, {
        sourceAccountId: fromAccount.accountId,
        destinationAccountId: toAccount.accountId,
        amount: amount,
        description: noteToSelf || "Transfer between accounts",
      });

      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Transfer failed:", error);
      setError(error.message || "Transfer failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const fromAccount = account;
  const toAccount = data.accounts.find(
    (acc) => acc.accountId === transferToAccountID
  );

  return (
    <div className="flex flex-col p-6 rounded-lg">
      <h1 className="text-2xl font-semibold mb-4">Transfer Money</h1>
      <p className="mb-6 text-gray-600">
        Select the accounts and the amount you want to transfer.
      </p>

      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        {/* Transfer From and To Dropdowns */}
        <div className="flex w-full gap-4">
          {/* Transfer From */}
          <div className="flex flex-col w-full">
            <label
              htmlFor="transfer-from"
              className="mb-2 font-medium text-gray-700"
            >
              Transfer from:
            </label>
            {/* <select
                            id='transfer-from'
                            value={selectedAccountId}
                            onChange={handleAccountChange}
                            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                        >
                            {data.accounts.map(account => (
                                <option key={account.id} value={account.id}>
                                    {typeDisplayNames[account.type]}
                                </option>
                            ))}
                        </select> */}

            <p className="text-lg font-semibold h-full items-center justify-center">
              {account.Name}
            </p>
          </div>

          {/* Transfer To */}
          <div className="flex flex-col w-full">
            <label
              htmlFor="transfer-to"
              className="mb-2 font-medium text-gray-700"
            >
              Transfer to:
            </label>
            <select
              id="transfer-to"
              value={transferToAccountID}
              onChange={handleTransferTo}
              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {data.accounts.map((account) => (
                <option key={account.accountId} value={account.accountId}>
                  {account.accountName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Display Available Balance */}
        <div className="flex w-full justify-between gap-4">
          {fromAccount && (
            <div className="flex flex-col w-full">
              <span className="font-medium text-gray-700">
                Available Balance:
              </span>
              <span className="text-lg font-semibold text-green-500">
                {formatUSD(fromAccount.Balance)}
              </span>
            </div>
          )}

          {toAccount && (
            <div className="flex flex-col w-full">
              <span className="font-medium text-gray-700">
                Available Balance:
              </span>
              <span className="text-lg font-semibold text-green-500">
                {formatUSD(toAccount.balance)}
              </span>
            </div>
          )}
        </div>

        {/* Transfer Amount Input */}
        <div className="flex flex-col">
          <label
            htmlFor="transfer-amount"
            className="mb-2 font-medium text-gray-700"
          >
            Amount:
          </label>
          <div className="p-4 rounded-lg border flex items-center gap-1">
            <span className="text-gray-700 font-medium">$</span>{" "}
            {/* Static $ sign */}
            <input
              type="text"
              id="transfer-amount"
              value={transferAmount}
              onChange={handleAmountChange}
              placeholder="0.00"
              className="p-2 border-none outline-none flex-1 placeholder-gray-400"
              inputMode="decimal" // Ensures a numeric keyboard on mobile
              pattern="^\d+(\.\d{0,2})?$" // HTML validation
              required
            />
          </div>
        </div>

        {/* Note to Self Input */}
        <div className="flex flex-col">
          <label
            htmlFor="transfer-note"
            className="mb-2 font-medium text-gray-700"
          >
            Note to self:
          </label>
          <div className="p-4 rounded-lg border w-full">
            <textarea
              id="transfer-note"
              value={noteToSelf}
              onChange={handleNoteToSelf}
              placeholder="Describe your reason for the transfer here"
              className="w-full h-full placeholder-gray-400"
              rows="3"
            ></textarea>
          </div>
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500">{error}</p>}

        {/* Submit Button */}
        <div className="flex w-full gap-4">
          <button
            type="button"
            onClick={onClose}
            className={`flex w-[30%] justify-center h-[80%] items-center p-4 font-inter font-semibold text-blue-500 text-md border border-blue-500 rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
          >
            Back
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`flex w-[70%] justify-center h-[80%] items-center p-4 font-inter font-semibold bg-blue-500 text-white-A700 text-md border rounded-lg hover:bg-blue-400 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100 ${
              isSubmitting ? "opacity-75 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? (
              <div className="h-6 w-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
            ) : (
              "Transfer"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TransferMoneyModal;
