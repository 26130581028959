import React, { useState } from "react";
import { formatUSD } from "utils";
import BalancesBar from "../BalancesBar";
import PhaseResults from "../PhaseResults";

const TeacherStudentPerformance = ({
  teacherData,
  onClose,
  phaseHistory,
  showBalances = true,
}) => {
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedAccountId, setExpandedAccountId] = useState(null);
  const [activeTab, setActiveTab] = useState("history"); // Default to event history

  console.log("teacherData", teacherData);

  if (!teacherData || !teacherData.playerState) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-600">No data available</p>
      </div>
    );
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const toggleRow = (eventId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      newSet.has(eventId) ? newSet.delete(eventId) : newSet.add(eventId);
      return newSet;
    });
  };

  const toggleAccount = (accountId) => {
    setExpandedAccountId((prevId) => (prevId === accountId ? null : accountId));
  };

  return (
    <div className="flex flex-col w-full rounded-lg pt-4 gap-4 overflow-auto">
      {showBalances && (
        <div className="flex-none">
          <BalancesBar gameState={teacherData} />
        </div>
      )}

      {/* Tab Navigation */}
      <div className="flex w-full border-b">
        <button
          onClick={() => setActiveTab("history")}
          className={`flex-1 text-center py-2 font-semibold transition-colors ${
            activeTab === "history"
              ? "border-b-4 border-blue-500 text-blue-600"
              : "text-gray-400 hover:text-gray-700"
          }`}
        >
          Event History & Accounts
        </button>
        <button
          onClick={() => setActiveTab("phaseHistory")}
          className={`flex-1 text-center py-2 font-semibold transition-colors ${
            activeTab === "phaseHistory"
              ? "border-b-4 border-blue-500 text-blue-600"
              : "text-gray-400 hover:text-gray-700"
          }`}
        >
          Phase History
        </button>
      </div>

      {/* Tab Content */}
      <div className="flex-1 overflow-y-auto p-4">
        {activeTab === "history" ? (
          // Event History & Accounts Tab
          <div className="grid grid-cols-2 gap-4 w-full overflow-auto h-full">
            {/* Event History */}
            <div className="border rounded-lg p-4 overflow-auto">
              <h2 className="text-xl font-semibold mb-4">Event History</h2>
              <div className="divide-y divide-gray-200">
                {[...teacherData.playerState.eventHistory]
                  .sort(
                    (a, b) =>
                      new Date(b.occurrenceDate) - new Date(a.occurrenceDate)
                  )
                  .map((event) => (
                    <div key={event.id} className="py-2">
                      <div
                        onClick={() => toggleRow(event.id)}
                        className="flex items-center justify-between p-2 cursor-pointer rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex items-center gap-4">
                          <span className="text-gray-600 text-sm">
                            {formatDate(event.occurrenceDate)}
                          </span>
                          <span className="font-medium">{event.name}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              event.choices.some(
                                (choice) => choice.isSelectedChoice
                              )
                                ? "bg-green-100 text-green-800"
                                : "bg-yellow-100 text-yellow-800"
                            }`}
                          >
                            {event.choices.some(
                              (choice) => choice.isSelectedChoice
                            )
                              ? "Completed"
                              : "Pending"}
                          </span>
                          <svg
                            className={`w-5 h-5 transform transition-transform ${
                              expandedRows.has(event.id) ? "rotate-180" : ""
                            }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </div>

                      {expandedRows.has(event.id) && (
                        <div className="pl-8 pr-4 py-3 bg-gray-50 rounded-lg mt-2">
                          <p className="text-gray-600 mb-3">
                            {event.description}
                          </p>
                          <div className="space-y-3">
                            {event.choices.map((choice) => (
                              <div
                                key={choice.id}
                                className={`p-3 rounded-lg ${
                                  choice.isSelectedChoice
                                    ? "bg-blue-50 border border-blue-200"
                                    : "bg-white border border-gray-200"
                                }`}
                              >
                                <div className="flex justify-between items-start">
                                  <div>
                                    <h4 className="font-medium">
                                      {choice.name}
                                    </h4>
                                    <p className="text-sm text-gray-600 mt-1">
                                      {choice.description}
                                    </p>
                                  </div>
                                  {choice.amount !== 0 && (
                                    <span
                                      className={`text-sm font-medium ${
                                        choice.amount > 0
                                          ? "text-green-600"
                                          : "text-red-600"
                                      }`}
                                    >
                                      {formatUSD(choice.amount)}
                                    </span>
                                  )}
                                </div>
                                {choice.isSelectedChoice && (
                                  <div className="mt-2 flex items-center">
                                    <span className="text-xs font-medium text-blue-600">
                                      Selected Choice
                                    </span>
                                  </div>
                                )}
                                {choice.choiceImpacts.length > 0 && (
                                  <div className="mt-2 flex flex-wrap gap-2">
                                    {choice.choiceImpacts
                                      .filter(
                                        (impact) => impact.impactValue !== 0
                                      )
                                      .map((impact, idx) => (
                                        <span
                                          key={idx}
                                          className={`text-xs px-2 py-1 rounded-full ${
                                            impact.metricName === "stress"
                                              ? impact.impactValue > 0
                                                ? "bg-red-100 text-red-800" // Increased stress is bad
                                                : "bg-green-100 text-green-800" // Decreased stress is good
                                              : impact.impactType === "Positive"
                                              ? "bg-green-100 text-green-800"
                                              : impact.impactType === "Negative"
                                              ? "bg-red-100 text-red-800"
                                              : "bg-gray-100 text-gray-800"
                                          }`}
                                        >
                                          {impact.metricName.replace("_", " ")}:{" "}
                                          {impact.metricName === "stress"
                                            ? impact.impactValue > 0
                                              ? "+" // Show + for increased stress (bad)
                                              : "-" // Show - for decreased stress (good)
                                            : impact.impactType === "Positive"
                                            ? "+"
                                            : impact.impactType === "Negative"
                                            ? "-"
                                            : ""}
                                          {(
                                            Math.abs(impact.impactValue) * 100
                                          ).toFixed(0)}
                                          %
                                        </span>
                                      ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            {/* Accounts & Transactions */}
            <div className="border rounded-lg p-4 overflow-auto">
              <h2 className="text-xl font-semibold mb-4">
                Accounts & Transactions
              </h2>
              <div className="divide-y divide-gray-200 overflow-auto">
                {teacherData.playerState.accounts.map((account) => (
                  <div key={account.accountId} className="py-2">
                    {/* Clickable Account Header */}
                    <div
                      onClick={() => toggleAccount(account.accountId)}
                      className="flex items-center justify-between p-2 cursor-pointer rounded-lg hover:bg-gray-50"
                    >
                      <div className="flex items-center gap-4 ">
                        <span className="font-medium">
                          {account.accountName}
                        </span>
                        <span
                          className={`text-sm font-medium ${
                            account.balance >= 0
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {formatUSD(account.balance)}
                        </span>
                      </div>
                      <svg
                        className={`w-5 h-5 transform transition-transform ${
                          expandedAccountId === account.accountId
                            ? "rotate-180"
                            : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>

                    {/* Expanded Transactions */}
                    {expandedAccountId === account.accountId && (
                      <div className="bg-gray-50 p-3 rounded-lg mt-2">
                        {account.transactions.length > 0 ? (
                          account.transactions.map((transaction) => (
                            <div
                              key={transaction.id}
                              className="border p-2 rounded-lg mt-2 bg-white"
                            >
                              <p className="text-sm">
                                <strong>{formatDate(transaction.date)}</strong>:{" "}
                                {transaction.description}{" "}
                                <span
                                  className={
                                    transaction.amount >= 0
                                      ? "text-green-600"
                                      : "text-red-600"
                                  }
                                >
                                  {formatUSD(transaction.amount)}
                                </span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="text-gray-500">No transactions</p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <PhaseResults results={phaseHistory || []} />
        )}
      </div>
    </div>
  );
};

export default TeacherStudentPerformance;
