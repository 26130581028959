import React, { useEffect, useState } from "react";
import { Button, Heading } from "..";
import { IconArrowRight } from "@tabler/icons-react";
import { postContinueStartup } from "StartupSimAPIService";
import StartupSimProgressPopup from "components/StartupSimProgressPopup";
import StartupSimCalendarPopup from "components/StartupSimCalendarPopup";
import { IconCalendarMonth } from "@tabler/icons-react";
import Lottie from "lottie-react";
import dicerollAnimation from "assets/animations/diceroll.json";

const StartupDecision = ({
  studentId,
  sessionData,
  setSessionData,
  stageNumber,
  setStageNumber,
  data,
  setData,
  unformattedDate,
  date,
  setDate,
  finishedStage,
  setFinishedStage,
  showCalendarPopup,
  setShowCalendarPopup,
  simDates,
  setSimDates,
  viewingOldDate,
  setViewingOldDate,
  readOnlyDecisions,
  setReadOnlyDecisions,
  setOldDateProgressId,
  fromTeacherView,
  stages,
  setStages,
  investments,
  setInvestments,
}) => {
  const [choiceDecision, setChoiceDecision] = useState("");
  // const [formattedDate, setFormattedDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [progressChange, setProgressChange] = useState({});

  // useEffect(() => {
  //   console.log(data)
  //   //format date
  //   const date = new Date(data?.presentedUTC);
  //   const options = { day: 'numeric', month: 'long', year: 'numeric' };
  //   const formatted = date.toLocaleDateString('en-GB', options);
  //   setFormattedDate(formatted);
  // },[])

  useEffect(() => {
    if(viewingOldDate){
      //console.log(data);
      if (data.choiceDecision === null) return;
      //alert("Here with: "+data.choiceDecision)
      if (data.choiceDecision) {
        //console.log(data.choiceDecision);
        setChoiceDecision(data.choiceDecision.toString());
      }
      //if(data.choiceDecision)  alert(data.choiceDecision);
    } else {
      setChoiceDecision("");
    }
  },[viewingOldDate, data])

  const selectChoiceClasses =
    "cursor-pointer border-2 border-solid border-blue-800 flex flex-col rounded-lg bg-white-A700 p-5 mx-5 mt-3 mb-2";
  const nonSelectedChoiceClasses =
    "cursor-pointer flex flex-col rounded-lg bg-white-A700 p-5 mx-5 mt-3 mb-2";

  const handleChoiceDecision = (choice) => {
    if (readOnlyDecisions === false || fromTeacherView === false) {
      setChoiceDecision(choice);
    }
  };

  const showTheCalendar = () => {
    setShowCalendarPopup(true);
  };

  const handleNext = async () => {
    //console.log(choiceDecision)
    if (readOnlyDecisions === false || fromTeacherView === false) {
      setIsSubmitting(true);
      try {
        await postContinueStartup(studentId, choiceDecision).then((promiseResult) => {
          //format date
          const date = new Date(promiseResult?.progress.simDecisionDate);
          date.setHours(date.getHours() + 6); //Fix for Time Zone
          const options = { day: 'numeric', month: 'long', year: 'numeric' };
          const formatted = date.toLocaleDateString('en-US', options);
          setDate(formatted);
          setChoiceDecision("");

            //get change
            if (
              promiseResult.progress.currentMarketValue !==
              data.currentMarketValue
            ) {
              setProgressChange({
                changed: "Current Market Value",
                was: data.currentMarketValue,
                now: promiseResult.progress.currentMarketValue,
              });
            }
            if (
              promiseResult.progress.employeeWellness !== data.employeeWellness
            ) {
              setProgressChange({
                changed: "Employee Wellness",
                was: data.employeeWellness,
                now: promiseResult.progress.employeeWellness,
              });
            }
            if (promiseResult.progress.grossRevenue !== data.grossRevenue) {
              setProgressChange({
                changed: "Gross Revenue",
                was: data.grossRevenue,
                now: promiseResult.progress.grossRevenue,
              });
            }
            if (promiseResult.progress.profitLoss !== data.profitLoss) {
              setProgressChange({
                changed: "Profit / Loss",
                was: data.profitLoss,
                now: promiseResult.progress.profitLoss,
              });
            }

            setSessionData(promiseResult.session_info);
            setStageNumber(promiseResult.stage);
            setData(promiseResult.progress);
            setInvestments(promiseResult.investmentOptions);
            setFinishedStage(promiseResult.finishedStage);
            const formattedEvents = promiseResult.simDates.map((item) => ({
              title: "",
              start: item.date,
              extendedProps: {
                progressId: item.progressId,
                affect: item.affect,
              }, // FullCalendar requires the date in 'YYYY-MM-DD' format
            }));
            setSimDates(formattedEvents);
            setIsSubmitting(false);
            setShowPopup(true);
            setStages(promiseResult.stages);
          }
        );

        return;
      } catch (error) {
        console.error("Error posting trade:", error);
        setChoiceDecision("");
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col">
      <div className="flex flex-row w-full px-4 py-[19px] justify-content items-center">
        <div className="flex items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
          <div className="flex self-start">
            <Heading
              size="11xl"
              as="h2"
              className="!font-poppins !text-gray-800_02"
            >
              <span
                className="cursor-pointer"
                onClick={() => showTheCalendar(true)}
              >
                {" "}
                <IconCalendarMonth style={{ display: "inline" }} /> {date}{" "}
                {viewingOldDate ? "-- Previous Decision" : ""}
              </span>
            </Heading>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full rounded-b-lg bg-slate-100">
        <div className="flex flex-row rounded-lg bg-white-A700 mx-5 my-10">
          {/* <div>
              <img src="/images/dwight.jpg" alt="Dwight Schrute" className="h-36 w-36 rounded-lg" />
            </div> */}
          <div className="p-5">
            <Heading
              size="7xl"
              as="h2"
              className="!font-poppins !text-gray-800_02"
            >
              {data?.situation}
            </Heading>
          </div>
        </div>
        <div
          className={
            choiceDecision === "1"
              ? selectChoiceClasses
              : nonSelectedChoiceClasses
          }
          onClick={() => handleChoiceDecision("1")}
        >
          <Heading
            size="4xl"
            as="h4"
            className="!font-poppins !text-gray-800_02"
          >
            {data?.choice1Text}
          </Heading>
        </div>
        <div
          className={
            choiceDecision === "2"
              ? selectChoiceClasses
              : nonSelectedChoiceClasses
          }
          onClick={() => handleChoiceDecision("2")}
        >
          <Heading
            size="4xl"
            as="h4"
            className="!font-poppins !text-gray-800_02"
          >
            {data?.choice2Text}
          </Heading>
        </div>
        <div
          className={
            choiceDecision === "3"
              ? selectChoiceClasses
              : nonSelectedChoiceClasses
          }
          onClick={() => handleChoiceDecision("3")}
        >
          <Heading
            size="4xl"
            as="h4"
            className="!font-poppins !text-gray-800_02"
          >
            {data?.choice3Text}
          </Heading>
        </div>
        {readOnlyDecisions === false || fromTeacherView === false ? (
          <div className="flex flex-row-reverse rounded-lg mx-5 my-5">
            <Button
              className="bg-blue-700 w-64 rounded-lg"
              size="6xl"
              disabled={choiceDecision ? false : true}
              onClick={handleNext}
            >
              <span className="text-white-A700">
                {isSubmitting ? "Please wait..." : "Next"}
              </span>
              {isSubmitting ? (
                <Lottie
                  animationData={dicerollAnimation}
                  loop={true}
                  style={{ width: 48, height: 48 }} // Adjust size as needed
                />
              ) : (
                <IconArrowRight className="ml-3" />
              )}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>

      <StartupSimProgressPopup
        open={showPopup}
        onClose={() => setShowPopup(false)}
        progressChange={progressChange}
        setShowPopup={setShowPopup}
        finishedStage={finishedStage}
        stages={stages}
        stageNumber={stageNumber}
        investments={investments}
        studentId={studentId}
        setData={setData}
      />
      <StartupSimCalendarPopup
        open={showCalendarPopup}
        onClose={() => setShowCalendarPopup(false)}
        setShowPopup={setShowCalendarPopup}
        unformattedDate={unformattedDate}
        simDates={simDates}
        setViewingOldDate={setViewingOldDate}
        setOldDateProgressId={setOldDateProgressId}
      />
    </div>
  );
};

export default StartupDecision;
