import React, { useEffect } from "react";
import GaugeChart from "react-gauge-chart";

const DashboardGaugeChart = ({ metric }) => {
  const { name, currentValue } = metric;

  // Define color schemes for different metrics
  const colorSchemes = {
    credit_score: ["#FF4C4C", "#FF8000", "#FFD700", "#ADFF2F", "#32CD32"],
    health: ["#FF4C4C", "#FF8000", "#FFD700", "#ADFF2F", "#32CD32"],
    social_life: ["#FF4C4C", "#FF8000", "#FFD700", "#ADFF2F", "#32CD32"],
    stress: ["#32CD32", "#ADFF2F", "#FFD700", "#FF8000", "#FF4C4C"], // Reversed colors for stress (lower is better)
    vehicle_reliability: ["#FF4C4C", "#FF8000", "#FFD700", "#ADFF2F", "#32CD32"],
  };

  // Get max value and determine if higher or lower is better based on metric type
  const getMetricConfig = () => {
    let maxValue = 1; // Default: Metrics are between 0 and 1 (percentage-based)

    if (name === "credit_score") {
      maxValue = 850; // Credit score max
    }

    return {
      maxValue,
      colors: colorSchemes[name] || colorSchemes.health,
      isInverted: name === "stress",
    };
  };

  const { maxValue, colors, isInverted } = getMetricConfig();

  // Normalize value
  const normalizedValue = name === "credit_score" 
    ? (currentValue / maxValue)  // Credit score stays within its range
    : currentValue;             // Other metrics stay between 0-1


  // Get gauge color based on thresholds
  const getValueColor = () => {
    if (name === "credit_score") {
      if (currentValue >= 700) return colors[4];
      if (currentValue >= 650) return colors[3];
      if (currentValue >= 600) return colors[2];
      if (currentValue >= 550) return colors[1];
      return colors[0];
    }

    // Other metrics follow percentage-based logic
    if (isInverted) {
      return currentValue >= 0.8 ? colors[4] :
             currentValue >= 0.6 ? colors[3] :
             currentValue >= 0.4 ? colors[2] :
             currentValue >= 0.2 ? colors[1] : colors[0];
    } else {
      return currentValue >= 0.8 ? colors[4] :
             currentValue >= 0.6 ? colors[3] :
             currentValue >= 0.4 ? colors[2] :
             currentValue >= 0.2 ? colors[1] : colors[0];
    }
  };

  const getOperatorSymbol = (operator) => {
    const operatorMap = {
      "Greater than or equal to": "≥",
      "Greater than": ">",
      "Less than or equal to": "≤",
      "Less than": "<",
      "Equal to": "=",
    };

    return operatorMap[operator] || operator; // Fallback: Return the original string if not found
  };

  // Format value based on metric type
  const formatMetricValue = () => {
    return name === "credit_score"
      ? currentValue.toFixed(0)  // Keep credit score as is
      : Math.round(currentValue * 100);  // Convert decimals to percentages for other metrics
  };

  const formatTotalValue = () => {
    return name === "credit_score"
      ? maxValue.toFixed(0)  // Keep credit score as is
      : Math.round(maxValue * 100);  // Convert decimals to percentages for other metrics
  };

  return (
    <div className="flex flex-col w-full rounded-lg border py-2 px-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h2 className="font-semibold">
            <span style={{ color: getValueColor() }} className="text-3xl">
              {formatMetricValue()}
            </span>
            <span className="text-2xl">/{formatTotalValue()}</span>
          </h2>
          <h1 className="font-inter font-semibold text-sm whitespace-nowrap capitalize">
            {name.replace(/_/g, " ")}
          </h1>
        </div>

        <div className="w-1/2">
          <GaugeChart
            id={`gauge-chart-${name}`}
            nrOfLevels={5}
            colors={colors}
            arcWidth={0.3}
            arcPadding={0}
            cornerRadius={0}
            percent={normalizedValue}
            textColor={"#000000"}
            needleColor={"#464A4F"}
            needleBaseColor={"#464A4F"}
            hideText={true}
          />
        </div>
      </div>

      <div className="flex flex-col text-xs text-accent font-semibold">
        {metric.thresholds ? (
          metric.thresholds
            .filter((threshold) => threshold.level.toLowerCase() === "success")
            .map((threshold, index) => (
              <p key={index}>
                Must be <span className="font-bold">{getOperatorSymbol(threshold.operator)} {threshold.value * (name === "credit_score" ? 1 : 100)} </span> 
                for phase success
              </p>
            ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default DashboardGaugeChart;
