import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import { useNavigate, useParams } from 'react-router-dom';
import { Img } from "components";
import BudgetSimConfiguration from "./BudgetSimConfiguration";

const TeacherSettings = () => {
  const { classroomID } = useParams();

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(`/classroom/${classroomID}`);
  };

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta
          name="description"
          content="Edit settings for startup simulator"
        />
      </Helmet>

      <div className="flex h-screen overflow-hidden">
        <Sidebar1 />

        <div className="flex flex-col w-full max-full overflow-hidden bg-mesh-gradient p-4 gap-4">
          <div className="flex justify-between rounded-lg bg-white-A700 shadow-md">
            <button className="flex p-4 gap-2" onClick={handleBackClick}>
              <Img
                src="/images/img_arrow_left_blue_a200.svg"
                alt="arrowleft"
                className="relative right-px"
              />
              <h2 className="!text-primary cursor-pointer justify-start text-md">
                Back
              </h2>
            </button>
            <HomeDate className={"rounded-lg"} />
          </div>

          <BudgetSimConfiguration />
        </div>
      </div>
    </>
  );
};

export default TeacherSettings;
