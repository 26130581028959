import React from "react";
import AccountComponent from "../AccountComponent";

const BudgetSimulatorAccounts = ({ accounts = [], simulatorId, onClose }) => {
    // Transform the new account format to match what AccountComponent expects
    const transformedAccounts = accounts.map(account => ({
        Name: account.accountName,
        Balance: account.balance,
        BankName: "Second National Bank", // This seems to be constant in the data
        Transactions: account.transactions.map(t => ({
            Type: t.type,
            Amount: t.amount,
            Description: t.description,
            Date: t.date
        })),
        CreditLimit: account.accountName.toLowerCase().includes('credit') ? account.creditLimit : undefined, // From metrics
        InterestRate: account.interestRate ?? 0.03, // Default from the sample data
        InterestType: account.accountName.toLowerCase().includes('credit') ? 'Charging' : 'Earning',
        accountId: account.accountId,
        minimumPayment: account.minimumPaymentDue ?? 0,
        interestRate: account.penaltyInterestRate ?? 0
    }));

    console.log(accounts);

    return (
        <div className="flex flex-col gap-4 p-4 w-full">
            {transformedAccounts.map((account, index) => (
                <AccountComponent key={index} account={account} accounts={accounts} simulatorId={simulatorId} onClose={onClose} />
            ))}
        </div>
    );
};

export default BudgetSimulatorAccounts;
