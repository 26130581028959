import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { addTeacherToClassroomByEmail } from 'TeacherAPIService';
import { useNavigate } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';

const AddInstructorPopup = ({ open, onClose, onInstructorAdded, classroomData }) => {
    
    const [statusMessage, setStatusMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [isInstructorAdded, setInstructorAdded] = useState(false);
    const [instructorEmail, setInstructorEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleAddInstructor = async () => {
        setLoading(true);
        try {
            const response = await addTeacherToClassroomByEmail(classroomData.id, instructorEmail);
            if (response.success) {
                setInstructorAdded(true);
                onInstructorAdded();
                setHasError(false);
                setStatusMessage(response.message);
            } else if (response.error === 401) {
                navigate("/timeout")
            } else {
                setHasError(true);
                setStatusMessage(response.message);
                console.error('Error adding instructor to class:', response.message);
            }
        } catch (error) {
            setHasError(true);
            setStatusMessage('An unexpected error occurred');
            console.error('Error adding instructor to class:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBackToAddInstructor = () => {
        setInstructorAdded(false);
        setInstructorEmail('');
        setHasError(false);
        setStatusMessage('');
        //onClose();
    };

    const handleClose = () => {
        setInstructorAdded(false);
        setInstructorEmail('');
        setHasError(false);
        setStatusMessage('');
        onClose();
    };


    return (
        <Popup open={open} closeOnDocumentClick onClose={handleClose} modal contentStyle={{ width: '400px', borderRadius: '1rem' }}>
            <div className="items-center justify-center h-full p-4">
                {!isInstructorAdded ? (
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-col bg-blue-500 items-center rounded-lg p-4 gap-2'>
                            {/* <Img src="/images/img_avatars_3d_avatar_26.png" alt="Invite a Co-Instructor" className="h-36 w-36" /> */}
                            <span className='text-white-A700 text-2xl'>Add a Co-Instructor</span>
                            <p className="text-white-A700 text-center">Enter the email address of the teacher you would like to add as a co-instructor to this class. The teacher must already be registered.</p>
                        </div>
                        
                        <div className='border rounded-lg border-gray-300'>
                            <input
                                type="email"
                                placeholder="coinstructor@yourschool.org"
                                value={instructorEmail}
                                onChange={(e) => setInstructorEmail(e.target.value)}
                                className="w-full border rounded-lg placeholder-gray-600 m-3"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button onClick={handleClose} className="mr-2 px-4 py-2 border border-blue-500 rounded text-blue-500">Cancel</button>
                            <button onClick={handleAddInstructor} className="px-4 py-2 bg-blue-500 text-white-A700 rounded" disabled={loading}>
                                {loading ? 'Adding Instructor...' : 'Add Instructor'}
                            </button>
                        </div>
                        {statusMessage !== "" && (
                        <div className="flex flex-col gap-4">
                            <div className={hasError ? ("flex items-center w-full justify-center p-4 rounded-lg bg-red-200") : ("flex items-center w-full justify-center p-4 rounded-lg bg-green-200")}>
                                    <p className="flex items-center gap-2">{statusMessage}</p>
                                </div>
                        </div>)}
                    </div>
                ) : (
                    <div>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            <span className='text-5xl text-green-600 rounded-full border p-4 w-[85px] text-center'>✓</span>
                            <h2 className="text-center font-semibold">Co-Instructor Added</h2>
                        </div>
                        <div className="mt-4 p-4 rounded">
                            <p className="text-center"><strong>{instructorEmail}</strong><br/>has been added as a co-instructor for this class!</p>
                        </div>
                        <div className="mt-4 flex justify-between">
                            <button onClick={handleBackToAddInstructor} className="px-4 py-2 border border-gray-300 rounded">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </Popup>
    );
};

export default AddInstructorPopup;
