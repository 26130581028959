import React, { useState, useEffect } from 'react';
import { formatUSD } from 'utils';
import FundamentalDataWidget from '../FundamentalDataWidget';

const FundamentalData = ({ stockInfo }) => {
  const [fundamentalData, setFundamentalData] = useState({
    marketCap: 0,
    peRatio: 0,
    dividendYield: 0,
    volume: 0,
    avgVolume: 0,
  });

  useEffect(() => {
    if (stockInfo) {
      setFundamentalData({
        marketCap: stockInfo.summary.marketCap || 0,
        peRatio: stockInfo.summary.peRatio || 0,
        dividendYield: stockInfo.summary.dividendYield || 0,
        volume: stockInfo.summary.volume || 0,
        avgVolume: stockInfo.summary.avgVolume || 0,
      });
    }
  }, [stockInfo]);

  return (
    <div className="space-y-6 h-full">
      
      <div className="h-full">
        <FundamentalDataWidget 
          symbol={stockInfo?.profile.symbol} 
          exchange={stockInfo?.profile.exchange || 'NASDAQ'} 
        />
      </div>
    </div>
  );
};

export default FundamentalData;