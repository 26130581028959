import React, { useState, useEffect } from 'react';
import Lottie from "lottie-react";
import loadingAnimation from "assets/animations/timeadvancing.json";

const loadingPhrases = [
  "Balancing your virtual checkbook... 💰",
  "Counting those digital pennies... 🪙",
  "Searching for loose change in the couch cushions... 🛋️",
  "Teaching piggy banks to fly... 🐷",
  "Calculating the cost of your coffee habit... ☕",
  "Negotiating with virtual landlords... 🏠",
  "Checking under the digital mattress... 🛏️",
  "Making sure money doesn't grow on trees... 🌳",
  "Downloading financial wisdom... 📚",
  "Chasing down runaway expenses... 🏃‍♂️",
  "Filling up your virtual wallet... 👛",
  "Training your savings to multiply... 📈",
  "Preparing your budget adventure... 🗺️",
  "Warming up the spreadsheet machine... 🤖",
  "Gathering financial fortune cookies... 🥠",
  "Teaching dollars to make sense... 💵",
  "Polishing your digital coins... ✨",
  "Summoning the budget wizard... 🧙‍♂️",
  "Loading life's price tags... 🏷️",
  "Consulting the piggy bank oracle... 🔮"
];

const LoadingPhrases = () => {
  const [phrase, setPhrase] = useState('');

  useEffect(() => {
    // Pick a random phrase when component mounts
    const randomPhrase = loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];
    setPhrase(randomPhrase);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-72 p-8">
      <div className="w-32 h-32 mb-4">
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
      <div className="text-lg text-gray-600 text-center font-medium animate-pulse">
        {phrase}
      </div>
    </div>
  );
};

export default LoadingPhrases;
