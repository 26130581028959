import React from "react";
import { formatUSD } from "utils";

const TransactionIcon = ({ type }) => {
  const getIconConfig = () => {
    switch (type.toLowerCase()) {
      case "deposit":
      case "income":
        return {
          color: "bg-green-100 text-green-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 11l5-5m0 0l5 5m-5-5v12"
              />
            </svg>
          ),
        };
      case "creditcardcharge":
        return {
          color: "bg-red-100 text-red-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 13l-5 5m0 0l-5-5m5 5V6"
              />
            </svg>
          ),
        };
      case "withdrawal":
        return {
          color: "bg-red-100 text-red-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 13l-5 5m0 0l-5-5m5 5V6"
              />
            </svg>
          ),
        };
      case "expense":
        return {
          color: "bg-red-100 text-red-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 13l-5 5m0 0l-5-5m5 5V6"
              />
            </svg>
          ),
        };
      case "transferin":
        return {
          color: "bg-blue-100 text-blue-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
              />
            </svg>
          ),
        };
      case "transferout":
        return {
          color: "bg-blue-100 text-blue-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
              />
            </svg>
          ),
        };
      default:
        return {
          color: "bg-gray-100 text-gray-600",
          icon: (
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          ),
        };
    }
  };

  const { color, icon } = getIconConfig();

  return <div className={`p-2 rounded-full ${color}`}>{icon}</div>;
};

const RecentTransactions = ({ accounts }) => {
  // Combine all transactions from all accounts and sort by date
  const allTransactions = accounts
    .flatMap((account) =>
      account.transactions.map((transaction) => ({
        ...transaction,
        accountName: account.name,
      }))
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 2); // Show only the 2 most recent transactions

  return (
    <div className="bg-white-A700 rounded-lg shadow p-4">
      <h2 className="text-lg font-semibold mb-4">Recent Transactions</h2>
      <div className="">
        {allTransactions.map((transaction, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-1 hover:bg-gray-50 rounded-lg transition-colors"
          >
            <div className="flex items-center space-x-3">
              <TransactionIcon type={transaction.type} />
              <div>
                <p className="font-medium text-gray-900">
                  {transaction.description}
                </p>
                <p className="text-sm text-gray-600">
                  {transaction.accountName}
                </p>
              </div>
            </div>
            <div className="text-right">
              <p
                className={`font-medium ${
                  transaction.type.toLowerCase() === "deposit" ||
                  transaction.type.toLowerCase() === "income" ||
                  transaction.type.toLowerCase() === "transferin"
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {transaction.type.toLowerCase() === "deposit" ||
                transaction.type.toLowerCase() === "income" ||
                transaction.type.toLowerCase() === "transferin"
                  ? "+"
                  : "-"}
                {formatUSD(Math.abs(transaction.amount))}
              </p>
              <p className="text-sm text-gray-600">
                {new Date(transaction.date).toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
        {allTransactions.length === 0 && (
          <div className="text-center py-4 text-gray-600">
            No recent transactions
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentTransactions;
