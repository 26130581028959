import React, { useState } from "react";
import Lottie from "lottie-react";
import stopAnimation from "assets/animations/stop.json";
import { formatUSD } from "utils";

const metricDisplayNames = {
  stress: "Stress",
  health: "Health",
  social_life: "Social Life",
  credit_score: "Credit Score",
  vehicle_reliability: "Vehicle Reliability",
};

const colorSchemes = {
  stress: ["#32CD32"],
  health: ["#FF4C4C"],
  social_life: ["#FF4C4C"],
  credit_score: ["#FF4C4C"],
  vehicle_reliability: ["#FF4C4C"],
};

const getMetricStyle = (metricName, value) => {
  // Convert decimal to percentage and determine if it's positive
  const percentage = Math.round(value * 100);
  const isPositive = percentage > 0;
  const isZero = percentage === 0;
  const displayValue = `${isPositive ? "+" : ""}${percentage}`;

  // If value is zero, return gray styling
  if (isZero) {
    return {
      text: "0",
      className:
        "px-2 py-1 rounded-lg font-medium bg-gray-100 text-gray-600 border border-gray-200",
    };
  }

  // For stress, positive is bad (red) and negative is good (green)
  if (metricName === "stress") {
    return {
      text: displayValue,
      className: `px-2 py-1 rounded-lg font-medium ${
        isPositive
          ? "bg-red-100 text-red-700 border border-red-200"
          : "bg-green-100 text-green-700 border border-green-200"
      }`,
    };
  }

  // For all other metrics, positive is good (green) and negative is bad (red)
  return {
    text: displayValue,
    className: `px-2 py-1 rounded-lg font-medium ${
      isPositive
        ? "bg-green-100 text-green-700 border border-green-200"
        : "bg-red-100 text-red-700 border border-red-200"
    }`,
  };
};

const NextEvent = ({
  event,
  onSubmit,
  accounts = [],
  isSubmitting = false,
}) => {
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [error, setError] = useState(null);

  // Validate event object
  if (!event || typeof event !== "object") {
    return (
      <div className="flex flex-col w-full h-full border rounded-lg shadow-md p-4 items-center justify-center gap-4">
        <div className="text-red-600 text-center">Invalid event data</div>
      </div>
    );
  }

  const handleChoiceClick = (choice) => {
    if (selectedChoice?.choiceId === choice.choiceId) {
      setSelectedChoice(null);
      setSelectedAccount(null);
      return;
    }
    setSelectedChoice(choice);
    setError(null);
  };

  const handleAccountSelect = (account) => {
    if (!account || !account.accountId) {
      console.error("Invalid account selected:", account);
      return;
    }
    setSelectedAccount(account);
    setError(null);
  };

  const handleSubmit = () => {
    if (!selectedChoice) {
      setError("Please select an option to continue");
      return;
    }

    // Only require account selection if the choice has a cost
    if (selectedChoice.amount > 0) {
      if (!selectedAccount) {
        setError("Please select an account to pay from");
        return;
      }

      if (!selectedAccount.accountId) {
        setError("Invalid account selection");
        return;
      }
    }

    // Only pass accountId if the choice has a cost
    const accountIdToSubmit =
      selectedChoice.amount > 0 ? selectedAccount.accountId : "";
    onSubmit(selectedChoice.choiceId, event.eventId, accountIdToSubmit);
  };

  return (
    <div className="flex flex-col w-full h-full border rounded-lg shadow-md overflow-hidden">
      <div className="flex-none p-4">
        <Lottie
          animationData={stopAnimation}
          loop={true}
          autoPlay
          className="w-32 h-32 mx-auto"
        />
      </div>

      <div className="flex flex-col flex-1 min-h-0">
        <div className="flex-none px-4">
          <h2 className="text-2xl font-bold text-center mb-4">{event.title}</h2>

          <div className="flex flex-col gap-2 w-full items-center justify-center text-gray-700 mb-4">
            <p className="text-center">{event.description}</p>

            {event.baseImpacts?.length > 0 && (
              <div className="flex flex-col gap-2 w-full items-center justify-center text-gray-700 mt-2">
                <p className="font-medium text-gray-600 mb-1">Base Impacts:</p>
                <div className="flex flex-wrap gap-2 justify-center">
                  {event.baseImpacts.map((impact) => {
                    if (!impact?.metricName || typeof impact.value !== "number")
                      return null;
                    const style = getMetricStyle(
                      impact.metricName,
                      impact.value
                    );
                    return (
                      <div
                        key={impact.id || impact.metricName}
                        className={style.className}
                      >
                        <span className="text-accent">
                          {metricDisplayNames[impact.metricName]}:{" "}
                        </span>
                        <span>{style.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 min-h-0">
          {/* Account Selection */}
          {selectedChoice?.amount > 0 && (
            <div className="flex flex-col gap-2 w-full  mx-auto mb-4">
              <p className="font-medium text-gray-600 text-center">
                Select Account to Pay From:
              </p>
              <div
                className={`grid ${
                  accounts.length === 2 ? "grid-cols-2" : "grid-cols-3"
                } gap-2 max-h-[200px] overflow-y-auto p-1`}
              >
                {accounts.map((account) => (
                  <div
                    key={account.accountId}
                    onClick={() => handleAccountSelect(account)}
                    className={`flex flex-col p-3 border-2 rounded-lg cursor-pointer transition-all relative
                      ${
                        selectedAccount?.accountId === account.accountId
                          ? "bg-blue-50 border-blue-500 shadow-lg transform scale-[1.02]"
                          : "hover:bg-gray-50 border-gray-200 hover:border-blue-300"
                      }`}
                  >
                    {selectedAccount?.accountId === account.accountId && (
                      <div className="absolute top-2 right-2 w-5 h-5 bg-blue-500 rounded-full flex items-center justify-center">
                        <svg
                          className="w-3 h-3 text-white"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                    )}
                    <p
                      className={`font-medium ${
                        selectedAccount?.accountId === account.accountId
                          ? "text-blue-700"
                          : "text-gray-700"
                      }`}
                    >
                      {account.accountName}
                    </p>
                    <p
                      className={`text-sm ${
                        selectedAccount?.accountId === account.accountId
                          ? "text-blue-600"
                          : "text-gray-600"
                      }`}
                    >
                      Balance: {formatUSD(account.balance)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Choices */}
          <div className="flex gap-4 w-full justify-center mb-4">
            {(event.choices || []).map((choice) => (
              <div
                key={choice.choiceId || choice.title}
                onClick={() => handleChoiceClick(choice)}
                className={`flex flex-col group justify-start gap-2 w-full items-start p-4 text-md border border-gray-200 rounded-lg hover:bg-blue-500 hover:text-white-A700 active:scale-100 ${
                  selectedChoice?.choiceId === choice.choiceId
                    ? "bg-blue-600 text-white-A700"
                    : "bg-white-A700"
                } transform transition-transform duration-300 ease-in-out hover:scale-105 cursor-pointer`}
              >
                <div className="flex flex-col gap-2 w-full h-full justify-between">
                  <div className="gap-2 flex flex-col">
                    <div className="flex justify-between items-center">
                      <h2 className="font-semibold">{choice.title}</h2>
                    </div>
                    <p className="text-sm text-left">{choice.description}</p>
                  </div>

                  <div className="flex flex-col gap-2">
                    {typeof choice.amount === "number" && (
                      <p
                        className={`font-semibold italic ${
                          choice.TransactionType === "Deposit"
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {choice.TransactionType === "Deposit"
                          ? formatUSD(choice.amount)
                          : `-${formatUSD(choice.amount)}`}
                      </p>
                    )}
                    <div className="flex flex-wrap gap-2">
                      {(choice.impacts || []).map((impact) => {
                        if (
                          !impact?.metricName ||
                          typeof impact.value !== "number"
                        )
                          return null;
                        const style = getMetricStyle(
                          impact.metricName,
                          impact.value
                        );
                        return (
                          <div
                            key={impact.id || impact.metricName}
                            className={style.className}
                          >
                            <span className="text-accent">
                              {metricDisplayNames[impact.metricName]}:{" "}
                            </span>
                            <span>{style.text}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex-none px-4 pb-4">
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={`w-full px-6 py-2 bg-blue-500 text-white-A700 rounded-lg transition-all flex items-center justify-center gap-2
            ${
              isSubmitting
                ? "opacity-75 cursor-not-allowed"
                : "hover:bg-blue-600"
            }`}
        >
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center h-6">
                <span className="w-2 h-2 mx-[2px] bg-blue-600 rounded-full opacity-75 animate-thinking1"></span>
                <span className="w-2 h-2 mx-[2px] bg-blue-600 rounded-full opacity-75 animate-thinking2"></span>
                <span className="w-2 h-2 mx-[2px] bg-blue-600 rounded-full opacity-75 animate-thinking3"></span>
              </div>
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </div>
  );
};

export default NextEvent;
