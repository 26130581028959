import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  createClassroomBudgetSim,
  getClassroomSimulations,
} from "BudgetSimulatorAPIService";

const defaultConfig = {
  lifePhases: [
    {
      name: "High School Student",
      duration: "2 months",
      taxRate: "15%",
      income: {
        type: "Employment",
        amount: "$250",
        frequency: "Bi-Weekly",
        fixed: true,
      },
      expenses: [
        { name: "Entertainment", amount: "$20", frequency: "Monthly" },
        { name: "Transportation", amount: "$60", frequency: "Monthly" },
      ],
      metrics: {
        Health: "80%",
        "Social Life": "70%",
        Stress: "40%",
        "Credit Score": "650",
      },
      successConditions: {
        "Credit Score": "≥ 650",
        Health: "≥ 70%",
        Stress: "≤ 60%",
      },
      failureConditions: {
        Stress: "≥ 90%",
        Health: "≤ 30%",
      },
      accounts: [
        { name: "Checking Account", balance: "$300", interestRate: "0.1%" },
        { name: "Savings Account", balance: "$200", interestRate: "2%" },
      ],
    },
    {
      name: "College Student",
      duration: "6 months",
      taxRate: "20%",
      income: {
        type: "Employment",
        amount: "$600",
        frequency: "Bi-Weekly",
        fixed: true,
      },
      expenses: [
        {
          name: "Books & Supplies",
          amount: "$200",
          frequency: "Semi-Annually",
        },
        { name: "Rent", amount: "$750", frequency: "Monthly" },
        { name: "Transportation", amount: "$150", frequency: "Monthly" },
        { name: "Phone & Internet", amount: "$100", frequency: "Monthly" },
        { name: "Entertainment", amount: "$150", frequency: "Monthly" },
        { name: "Food", amount: "$75", frequency: "Bi-Weekly" },
      ],
      metrics: {
        Health: "75%",
        "Social Life": "80%",
        Stress: "50%",
        "Credit Score": "675",
      },
      successConditions: {
        "Credit Score": "≥ 700",
        Health: "≥ 60%",
        Stress: "≤ 70%",
      },
      failureConditions: {
        "Credit Score": "≤ 500",
        Stress: "≥ 90%",
        Health: "≤ 40%",
      },
      accounts: [
        { name: "Checking Account", balance: "$4,000", interestRate: "0.1%" },
        { name: "Savings Account", balance: "$800", interestRate: "2.5%" },
        {
          name: "Credit Card",
          balance: "$0",
          creditLimit: "$1,500",
          interestRate: "19.99%",
        },
      ],
    },
    {
      name: "Full-Time Employee",
      duration: "6 months",
      taxRate: "25%",
      income: {
        type: "Employment",
        amount: "$2,700",
        frequency: "Bi-Weekly",
        fixed: true,
      },
      expenses: [
        { name: "Rent", amount: "$1,400", frequency: "Monthly" },
        { name: "Utilities", amount: "$175", frequency: "Monthly" },
        { name: "Health Insurance", amount: "$200", frequency: "Monthly" },
        { name: "Car Payment", amount: "$300", frequency: "Monthly" },
        { name: "Car Insurance", amount: "$150", frequency: "Monthly" },
        { name: "Gas & Transportation", amount: "$200", frequency: "Monthly" },
        { name: "Groceries", amount: "$350", frequency: "Monthly" },
        { name: "Phone & Internet", amount: "$100", frequency: "Monthly" },
        { name: "Entertainment", amount: "$300", frequency: "Monthly" },
        { name: "401k Contribution", amount: "$300", frequency: "Monthly" },
        { name: "Food", amount: "$75", frequency: "Bi-Weekly" },
      ],
      metrics: {
        Health: "75%",
        "Social Life": "70%",
        Stress: "50%",
        "Credit Score": "720",
      },
      successConditions: {
        "Credit Score": "≥ 750",
        Health: "≥ 70%",
        Stress: "≤ 60%",
      },
      failureConditions: {
        "Credit Score": "≤ 600",
        Health: "≤ 40%",
        Stress: "≥ 90%",
      },
      accounts: [
        { name: "Checking Account", balance: "$3,000", interestRate: "0.2%" },
        { name: "Savings Account", balance: "$10,000", interestRate: "3%" },
        {
          name: "Credit Card",
          balance: "$0",
          creditLimit: "$8,000",
          interestRate: "14.99%",
        },
      ],
    },
    {
      name: "Early Retiree",
      duration: "6 months",
      taxRate: "15%",
      income: {
        type: "Passive Income",
        amount: "$4,000",
        frequency: "Monthly",
        fixed: true,
      },
      expenses: [
        { name: "Mortgage", amount: "$2,000", frequency: "Monthly" },
        { name: "Property Tax", amount: "$500", frequency: "Monthly" },
        { name: "Home Insurance", amount: "$200", frequency: "Monthly" },
        { name: "Utilities", amount: "$300", frequency: "Monthly" },
        { name: "Health Insurance", amount: "$600", frequency: "Monthly" },
        { name: "Car Insurance", amount: "$100", frequency: "Monthly" },
        { name: "Gas & Transportation", amount: "$150", frequency: "Monthly" },
        { name: "Groceries", amount: "$600", frequency: "Monthly" },
        { name: "Phone & Internet", amount: "$150", frequency: "Monthly" },
        { name: "Food", amount: "$75", frequency: "Bi-Weekly" },
        {
          name: "Entertainment & Travel",
          amount: "$1,000",
          frequency: "Monthly",
        },
        { name: "Healthcare Expenses", amount: "$300", frequency: "Monthly" },
      ],
      metrics: {
        Health: "80%",
        "Social Life": "80%",
        Stress: "30%",
        "Credit Score": "800",
      },
      successConditions: {
        "Credit Score": "≥ 800",
        Health: "≥ 70%",
        "Social Life": "≥ 70%",
        Stress: "≤ 40%",
      },
      failureConditions: {
        Health: "≤ 40%",
        Stress: "≥ 80%",
      },
      accounts: [
        { name: "Checking Account", balance: "$5,000", interestRate: "0.2%" },
        { name: "Savings Account", balance: "$25,000", interestRate: "3.5%" },
        {
          name: "Credit Card",
          balance: "$0",
          creditLimit: "$15,000",
          interestRate: "12.99%",
        },
      ],
    },
  ],
};

const LifePhaseCard = ({ phase }) => (
  <div className="border rounded-lg p-4 mb-4 bg-white">
    <h3 className="text-lg font-semibold mb-2">{phase.name}</h3>
    <p className="text-sm text-gray-600 mb-2">Duration: {phase.duration}</p>

    <div className="mb-3">
      <h4 className="font-medium text-sm mb-1">Income</h4>
      <p className="text-sm">
        {phase.income.amount} {phase.income.frequency} {}
      </p>
      <p className="text-xs text-gray-600">
        {phase.income.fixed ? "Fixed" : "Variable"}
      </p>
      <p className="text-sm">{phase.taxRate} tax rate</p>
    </div>

    {phase.expenses && (
      <div className="mb-3">
        <h4 className="font-medium text-sm mb-1">Expenses</h4>
        <div className="grid grid-cols-2 gap-2">
          {phase.expenses.map((expense, i) => (
            <p key={i} className="text-sm">
              {expense.name}: {expense.amount} ({expense.frequency})
            </p>
          ))}
        </div>
      </div>
    )}

    <div className="mb-3">
      <h4 className="font-medium text-sm mb-1">Starting Metrics</h4>
      <div className="grid grid-cols-2 gap-2">
        {Object.entries(phase.metrics).map(([metric, value]) => (
          <p key={metric} className="text-sm">
            {metric}: {value}
          </p>
        ))}
      </div>
    </div>

    <div className="mb-3">
      <h4 className="font-medium text-sm mb-1">Success Conditions</h4>
      <div className="grid grid-cols-2 gap-2">
        {Object.entries(phase.successConditions).map(([metric, value]) => (
          <p key={metric} className="text-sm">
            {metric}: {value}
          </p>
        ))}
      </div>
    </div>

    <div className="mb-3">
      <h4 className="font-medium text-sm mb-1">Failure Conditions</h4>
      <div className="grid grid-cols-2 gap-2">
        {Object.entries(phase.failureConditions).map(([metric, value]) => (
          <p key={metric} className="text-sm">
            {metric}: {value}
          </p>
        ))}
      </div>
    </div>

    <div>
      <h4 className="font-medium text-sm mb-1">Accounts</h4>
      <div className="grid gap-2">
        {phase.accounts.map((account, i) => (
          <p key={i} className="text-sm">
            {account.name}: {account.balance || account.limit}
            {account.interestRate
              ? ` (${account.interestRate} APY)`
              : ` (${account.apr} APR)`}
            {account.creditLimit && ` ${account.creditLimit} Limit`}
          </p>
        ))}
      </div>
    </div>
  </div>
);

const BudgetSimConfiguration = () => {
  const [simulations, setSimulations] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { classroomID } = useParams();

  useEffect(() => {
    const fetchSimulations = async () => {
      setIsLoading(true);
      try {
        const response = await getClassroomSimulations(classroomID);
        // Check if response is an error message or actual simulation data
        if (
          typeof response === "string" &&
          response.includes("No simulation found")
        ) {
          setSimulations(null);
        } else {
          setSimulations(response);
        }
      } catch (error) {
        console.error("Failed to fetch simulations:", error);
        setSimulations(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (classroomID) {
      fetchSimulations();
    }
  }, [classroomID]);

  const handleCreateBudgetSim = async () => {
    setIsCreating(true);
    setShowSuccess(false);
    try {
      const result = await createClassroomBudgetSim(classroomID, defaultConfig);
      if (result) {
        setSimulations([result]);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      }
    } catch (error) {
      console.error("Failed to create budget simulation:", error);
    } finally {
      setIsCreating(false);
    }
  };

  const hasActiveSimulation = simulations;

  return (
    <div className="flex flex-col w-full h-[90%] rounded-lg shadow-md bg-white-A700 p-4 gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">
            {hasActiveSimulation
              ? "Active Simulation"
              : "Create New Simulation"}
          </h2>
          {!isLoading && !hasActiveSimulation && (
            <button
              onClick={handleCreateBudgetSim}
              disabled={isCreating}
              className="button-blue !py-2 min-w-[120px] h-10 flex items-center justify-center"
            >
              {isCreating ? (
                <div className="flex justify-center items-center h-6">
                  <span className="w-2 h-2 mx-[2px] bg-white-A700 rounded-full opacity-75 animate-thinking1"></span>
                  <span className="w-2 h-2 mx-[2px] bg-white-A700 rounded-full opacity-75 animate-thinking2"></span>
                  <span className="w-2 h-2 mx-[2px] bg-white-A700 rounded-full opacity-75 animate-thinking3"></span>
                </div>
              ) : (
                "Activate Budget Sim"
              )}
            </button>
          )}
        </div>
        {showSuccess && (
          <div className="flex items-center text-green-600 text-sm bg-green-50 p-2 rounded">
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Budget simulation created successfully!
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center p-4">
          <div className="flex justify-center items-center h-6">
            <span className="w-2 h-2 mx-[2px] bg-blue-600 rounded-full opacity-75 animate-thinking1"></span>
            <span className="w-2 h-2 mx-[2px] bg-blue-600 rounded-full opacity-75 animate-thinking2"></span>
            <span className="w-2 h-2 mx-[2px] bg-blue-600 rounded-full opacity-75 animate-thinking3"></span>
          </div>
        </div>
      ) : hasActiveSimulation ? (
        <>
          <div className="text-sm text-gray-600 mb-4 p-4 bg-blue-50 rounded-lg">
            There is already an active simulation for this classroom. You cannot
            create a new simulation until the current one is completed.
          </div>

          <div className="text-sm text-gray-600 mb-4">
            Below is the default configuration for the budget simulation. In the
            future, you'll be able to customize these settings.
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-auto">
            {defaultConfig.lifePhases.map((phase, index) => (
              <LifePhaseCard key={index} phase={phase} />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-sm text-gray-600 mb-4">
            Below is the default configuration for the budget simulation. In the
            future, you'll be able to customize these settings.
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-auto">
            {defaultConfig.lifePhases.map((phase, index) => (
              <LifePhaseCard key={index} phase={phase} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BudgetSimConfiguration;
