import React, { useState } from "react";
import { Img } from "./..";
import ClassPopup from "components/CreateClassPopup";
import { useNavigate, useParams } from "react-router-dom";

export default function QuickLink({ classroomData }) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { classroomID } = useParams();

  const navigate = useNavigate();

  const handleCreateAssignment = () => {
    navigate(`/classroom/${classroomID}/createassignment`);
  };

  const handleSimulatorSettings = () => {
    navigate(`/classroom/${classroomID}/simulatorsettings`);
  };

  const handleBudgetSimulatorSettings = () => {
    navigate(`/classroom/${classroomID}/budgetsettings`);
  };

  return (
    <div className="flex flex-col gap-4 h-[65vh]">
      <h1 className="text-sm">Quick Links</h1>

      <div className="flex flex-col gap-4">
        <button
          onClick={() => setPopupOpen(true)}
          className="w-full p-2 border bg-white-A700 rounded-lg hover:scale-105 hover:bg-gray-100 transition"
        >
          <div className="flex gap-2">
            <div className="flex flex-col w-fit h-fit aspect-square p-2 items-center rounded-md bg-purple-50 border">
              <Img
                src="/images/img_user_gray_800_01.svg"
                alt="add_class"
                className={"aspect-square min-h-[24px] min-w-[24px]"}
              />
            </div>

            <div className="flex w-full justify-between items-center tracking-[0.50px] !text-gray-800_01">
              <span className="text-xs text-left lg:text-sm">Create Class</span>
              <Img
                src="/images/img_plus.svg"
                alt="add_class"
                className="h-[12px] w-[12px] xl:h-[16px] xl:w-[16px]"
              />
            </div>
          </div>
        </button>

        <ClassPopup open={isPopupOpen} onClose={() => setPopupOpen(false)} />

        <button
          className="w-full p-2 border bg-white-A700 rounded-lg hover:scale-105 hover:bg-gray-100 transition"
          onClick={handleCreateAssignment}
        >
          <div className="flex gap-2 items-center">
            <div className="flex flex-col w-fit h-fit aspect-square p-2 items-center rounded-md bg-green-50 border">
              <Img
                src="/images/img_television_gray_800_01.svg"
                className={"aspect-square min-h-[24px] min-w-[24px]"}
              />
            </div>

            <div className="flex w-full justify-between items-center tracking-[0.50px] !text-gray-800_01">
              <span className="text-xs text-left lg:text-sm">
                Create Assignments
              </span>
              <Img
                src="/images/img_plus.svg"
                alt="add_class"
                className="h-[12px] w-[12px] xl:h-[16px] xl:w-[16px]"
              />
            </div>
          </div>
        </button>

        <button
          className="w-full p-2 border bg-white-A700 rounded-lg hover:scale-105 hover:bg-gray-100 transition"
          onClick={handleSimulatorSettings}
        >
          <div className="flex gap-2 items-center">
            <div className="flex flex-col w-fit h-fit aspect-square p-2 items-center rounded-md bg-blue-50 border">
              <Img
                src="/images/bar-chart-nopadding.svg"
                className={"aspect-square min-h-[24px] min-w-[24px]"}
              />
            </div>

            <div className="flex w-full justify-between items-center tracking-[0.50px] !text-gray-800_01">
              <span className="text-xs text-left lg:text-sm">
                Startup Simulator Settings
              </span>
              <Img
                src="/images/img_plus.svg"
                alt="add_class"
                className="h-[12px] w-[12px] xl:h-[16px] xl:w-[16px]"
              />
            </div>
          </div>
        </button>

        <button
          className="w-full p-2 border bg-white-A700 rounded-lg hover:scale-105 hover:bg-gray-100 transition items-center justify-center flex"
          onClick={handleBudgetSimulatorSettings}
        >
          <div className="flex gap-2 items-center">
            <div className="flex flex-col w-fit h-fit aspect-square items-center rounded-md bg-yellow-50 border">
              <Img
                src="/images/img_container_gray_800_01_22x22.svg"
                alt="Budget Icon"
                className={"aspect-square min-h-[42px] min-w-[42px]"}
              />
            </div>

            <div className="flex w-full justify-between items-center tracking-[0.50px] !text-gray-800_01">
              <span className="text-xs text-left lg:text-sm">
                Budget Simulator Settings
              </span>
              <Img
                src="/images/img_plus.svg"
                alt="add_class"
                className="aspect-square h-[12px] w-[12px] xl:h-[16px] xl:w-[16px]"
              />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}
