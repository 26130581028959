import React, { useState, useEffect } from 'react';

const StartupProgressList = ({ startupSimData, height }) => {
    const [startupType, setStartupType] = useState("");

    useEffect(() => {
        if (startupSimData ) {
        
            switch(startupSimData.sessionInfo.startupType){
                case "service":
                    setStartupType("Service Startup");
                    break;
                case "tech":
                    setStartupType("Technology Startup");
                    break;
                case "product":
                    setStartupType("Product Startup");
                    break;
                default:
                    break;
            }
        }
    }, [startupSimData]);

    const cellStyle = {
        border: '1px solid black',
        padding: '8px',
        textAlign: 'left',
    };
    const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };

    return (
        <div>
            <h2>Situations, Choices, Decisions, and Results</h2>
            <br/>
            {(startupSimData.progressRecords) ? (
                <>
                    <h2>{startupType}</h2>
                    <br/>
                    {startupSimData.progressRecords.map((item, index) => { 
                        return (
                            <>
                                <table>
                                    <tr>
                                        <td style={cellStyle}>Current Market Value</td>
                                        <td style={cellStyle}>Employee Wellness</td>
                                        <td style={cellStyle}>Gross Revenue</td>
                                        <td style={cellStyle}>Profit / Loss</td>
                                    </tr>
                                    <tr>
                                        <td style={cellStyle}>{new Intl.NumberFormat('en-US', numberFormatOptions).format(item.currentMarketValue)}</td>
                                        <td style={cellStyle}>{new Intl.NumberFormat('en-US').format(item.employeeWellness)}</td>
                                        <td style={cellStyle}>{new Intl.NumberFormat('en-US', numberFormatOptions).format(item.grossRevenue)}</td>
                                        <td style={cellStyle}>{new Intl.NumberFormat('en-US', numberFormatOptions).format(item.profitLoss)}</td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td>{item.simDecisionDate} {(item.simDecisionDate === startupSimData.latestDate) ? "-- Current Decision" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>{item.situation}</td>
                                    </tr>
                                    <tr>
                                        <td><span style={(item.choiceDecision === 1) ? {fontWeight: 'bold'} : {}}>Choice #1: {item.choice1Text}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={(item.choiceDecision === 2) ? {fontWeight: 'bold'} : {}}>Choice #2: {item.choice2Text}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={(item.choiceDecision === 3) ? {fontWeight: 'bold'} : {}}>Choice #3: {item.choice3Text}</span></td>
                                    </tr>
                                </table>
                                
                                <br/>
                                <br/>
                            </>
                        );
                    })}
                </>
            ) : (
                <p>No Decisions Yet</p>
            )}
            {/*
            {(startupSimData.length) ? (
                <ol>
                    {startupSimData.map((item, index) => { 
                        const selectedText = `choice${item.choiceDecision}Text`;
                        const selectedMetric = `choice${item.choiceDecision}Metric`;
                        const selectedAffect = `choice${item.choiceDecision}Affect`;
                        return (
                            <li key={item.id}>
                                <h2>{index+1}. {item.situation}</h2>
                                {item.choiceDecision &&
                                    <p>----{item[selectedText]} -- {item[selectedMetric]} -- {item[selectedAffect]}</p>
                                }
                            </li>
                        );
                    })}
                </ol>
            ) : (
                <p>No Decisions Yet</p>
            )}
            */}
        </div>
    );
};

export default StartupProgressList;