// BudgetSimLandingPage.jsx
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { Img } from "components";
import StudentSidebar from "components/StudentSidebar";
import HomeDate from "components/HomeDate";
import { IconClock, IconBulb, IconFlag } from "@tabler/icons-react";
import StartQuestion from "components/BudgetSimulatorComponents/StartQuestion";
import {
  postBeginBudget,
  joinBudgetSimulation,
  getClassroomSimulations,
} from "BudgetSimulatorAPIService";
import { useNavigate } from "react-router-dom";
import { fetchStudentId, fetchStudentDetails } from "SimulatorAPIService";
import { useUser } from "UserContext";

export default function BudgetSimLandingPage() {
  const navigate = useNavigate();
  const { userInfo, updateUserInfo } = useUser();
  const [isQuestions, setIsQuestions] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    if (questionIndex === 5) {
      setQuestionIndex(0);
      return;
    }
    setQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleJoinSimulation = async (event) => {
    event?.preventDefault(); // Prevents default form submission if applicable
    setIsLoading(true);
    setError("");

    try {
      // Fetch student's classroom details
      const studentDetails = await fetchStudentDetails(userInfo.studentId);

      if (
        !studentDetails ||
        !studentDetails.courses ||
        studentDetails.courses.length === 0
      ) {
        setError("You are not currently enrolled in a classroom.");
        setIsLoading(false);
        return;
      }

      const classroomId = studentDetails.courses[0].class.id;

      try {
        const simulations = await getClassroomSimulations(classroomId);
        console.log(simulations, "here");

        if (!simulations || typeof simulations !== "object" || Object.keys(simulations).length === 0) {
          setError(
            "Your teacher hasn't created a budget simulation yet. Please ask them to set one up."
          );
          setIsLoading(false);
          return;
        }

        // Try to join the simulation
        await joinBudgetSimulation(simulations.id, userInfo.studentId);

        // If successful, navigate to the simulator
        navigate("/budgetsimulator");
      } catch (error) {
        console.error("Error fetching classroom simulations:", error);

        if (error.response && error.response.status === 404) {
          setError(
            "No budget simulation found for this classroom. Please ask your teacher to create one."
          );
        } else {
          setError("An error occurred while fetching the budget simulation.");
        }
      }
    } catch (error) {
      console.error("Error joining simulation:", error);
      setError("Failed to join the simulation. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBegin = () => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();
        if (!id) {
          navigate("/timeout");
        }

        const begin = postBeginBudget(id);

        if (begin) {
          navigate("/budgetsimulator");
        }
      } catch (error) {
        console.error("Error beginning budget simulator", error);
      }
    };

    fetchData();
  };


  return (
    <>
      <Helmet>
        <title>Intertwined - Budget Simulator</title>
        <meta
          name="description"
          content="Intertwined Budget Simulator Landing Page"
        />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto bg-mesh-gradient">
        <div>
          <StudentSidebar />
        </div>

        <div className="flex flex-col w-full m-4 gap-4">
          <HomeDate className="w-full px-4 rounded-lg shadow-md" />

          {isQuestions ? (
            <div className="flex items-start justify-center h-full">
              <StartQuestion
                index={questionIndex}
                question="How much money do you spend on average each week?"
                answers={["Answer 1", "Answer 2", "Answer 3", "Answer 4"]}
                nextQuestion={handleNext}
                submitQuestion={handleBegin}
              />
            </div>
          ) : (
            // Render Landing Page Content when isQuestions is false
            <div className="flex flex-col grow rounded-xl border p-6 gap-6 bg-white-A700 shadow-md">
              <div className="flex gap-4">
                <div className="w-[50%]">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <h2 className="font-bold text-3xl">Budget Simulator</h2>

                      <div className="flex items-center">
                        <IconClock className="flex h-[12px]" />
                        <p className="text-sm">Duration: 30 Minutes</p>
                      </div>
                    </div>

                    <p className="leading-relaxed">
                      The Intertwined Personal Budget Simulator is an
                      interactive educational tool designed for you to learn
                      essential financial skills through a realistic and
                      engaging game environment. You will manage a virtual
                      budget, make financial decisions, and face real-life
                      financial scenarios to develop practical money management
                      skills. The simulator aims to enhance financial literacy,
                      preparing students for future financial independence.
                    </p>
                  </div>
                </div>

                <div className="w-[50%]">
                  <Img
                    src="../images/img_adobestock_124712720.png"
                    alt="Budget Simulator Illustration"
                    className="h-full rounded-lg"
                  />
                </div>
              </div>

              <div className="flex gap-4">
                <div className="flex flex-col w-[50%] rounded-2xl shadow-md border p-6 gap-4">
                  <h3 className="flex gap-2 text-lg font-semibold">
                    <IconBulb />
                    Learning Objectives
                  </h3>

                  <ul className="pointer-events-none list-disc list-inside flex flex-col gap-2 text-sm">
                    <li>Learning to build and maintain a credit score</li>
                    <li>Budgeting a paycheck</li>
                    <li>Tracking expenses and income</li>
                    <li>Saving for short-term and long-term goals</li>
                    <li>Managing debt and loans</li>
                    <li>Understanding taxes and deductions</li>
                    <li>Making informed financial decisions</li>
                    <li>
                      Maintaining a wellness meter that balances financial and
                      personal well-being
                    </li>
                  </ul>
                </div>

                <div className="flex flex-col w-[50%] rounded-2xl shadow-md border p-6 gap-4">
                  <h3 className="flex gap-2 text-lg font-semibold">
                    <IconFlag />
                    Scenario Goals
                  </h3>

                  <ul className="pointer-events-none list-disc list-inside flex flex-col gap-2 text-sm">
                    <li>
                      Successfully manage a monthly budget without incurring
                      debt
                    </li>
                    <li>Achieve and maintain a high credit score</li>
                    <li>
                      Save a specific amount of money within a set timeframe
                    </li>
                    <li>
                      Make informed financial decisions that positively impact
                      both financial health and personal wellness
                    </li>
                    <li>
                      Understand the consequences of financial choices through
                      realistic scenarios and challenges
                    </li>
                  </ul>
                </div>
              </div>

              <div className="w-full flex flex-col items-center gap-2">
                <button
                  onClick={handleJoinSimulation}
                  className="button-blue w-[20%] !py-2"
                  disabled={isLoading}
                >
                  {isLoading ? "Joining..." : "Join Simulation"}
                </button>
                {error && (
                  <p className=" text-red-500 text-center text-sm">
                    {error}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
